<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      v-if="lineID == 'address_line_1'"
      aut-address-line-1
      :context="context"
      :label="label"
      v-model="line"
      :mandatory="isMandatory"
      @change="emitUpdate"
    />
    <AddressInput
      v-else-if="lineID == 'address_line_2'"
      aut-line-2
      :context="context"
      :label="label"
      v-model="line"
      :mandatory="isMandatory"
      @change="emitUpdate"
    />
  </v-col>
</template>
<script>
import address_mixin from "./address_mixin";
const debug = require('debug')('atman.components.fields.address.address_line')  // eslint-disable-line
export default {
  name: "AddressLine",
  mixins: [address_mixin],
  props: {
    lineID: {
      type: String,
    },
    context: {},
    value: {},
    definition: {},
  },
  watch: {
    "value.address_line_1"() {
      if (this.lineID != "address_line_1") {
        return;
      }
      debug(`Updating line`, this.value?.[this.lineID]);
      this.line = this.value?.[this.lineID];
    },
    "value.address_line_2"() {
      if (this.lineID != "address_line_2") {
        return;
      }
      debug(`Updating line`, this.value?.[this.lineID]);
      this.line = this.value?.[this.lineID];
    },
  },
  data() {
    return {
      line: this._getValue(this.lineID),
    };
  },
  computed: {
    fieldProperties() {
      const configuration =
        this.definition?.address_fields?.[this.lineID] || {};
      return configuration;
    },
  },
  mounted() {
    debug(this.definition, this.value);
  },
  created() {
    const defaults = {
      label:
        this.lineID == "address_line_1" ? "Address Line 1" : "Address Line 2",
      display: {
        width: "12",
      },
    };
    this.defaults = defaults;
  },
  methods: {
    emitUpdate($event) {
      this.$emit("update", { [this.lineID]: $event });
    },
  },
};
</script>
