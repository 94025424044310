export default {
  label: "Rule Tutorial",
  type: "rule_tutorial",
  filters: ["form_editor", "list_editor", "field_tutorial"],
  description: "Examples of different rules",
  properties: [],
  templates: [
    {
      id: "mandatory",
      label: "Mandatory",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ mandatory: true }],
      },
    },
    {
      id: "mandatory_with_custom_text",
      label: "Mandatory with custom message",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [
          {
            mandatory: {
              message: "Title is mandatory",
            },
          },
        ],
      },
    },
    {
      id: "min_length",
      label: "Minimum Length",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ min_length: "2" }],
      },
    },
    {
      id: "max_length",
      label: "Maximum Length",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ max_length: "2" }],
      },
    },
    {
      id: "has_numbers",
      label: "Should have Numeric characters",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ has_numbers: true }],
      },
    },
    {
      id: "has_upper_case",
      label: "Should have Uppercase characters",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ has_upper_case: true }],
      },
    },
    {
      id: "has_lower_case",
      label: "Should have Lowercase characters",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ has_lower_case: true }],
      },
    },
    {
      id: "only_lower_case",
      label: "Should have only Lowercase characters",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ only_lower_case: true }],
      },
    },
    {
      id: "internal_name",
      label: "Should have only Lowercase characters, digits or underscore",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ internal_name: true }],
      },
    },
    {
      id: "has_special_characters",
      label: "Should have Special characters",
      value: {
        name: "some_name",
        label: "Title",
        type: "text",

        rules: [{ has_special_characters: true }],
      },
    },
    {
      id: "email",
      label: "Should be a valid Email",
      value: {
        name: "some_name",
        label: "Email",
        type: "text",

        rules: [{ email: true }],
      },
    },
    {
      id: "email_list",
      label: "List of emails",
      value: {
        name: "some_name",
        label: "Emails",
        type: "text",

        rules: [{ email_list: true }],
      },
    },
    {
      id: "phone_number",
      label: "Should be a valid Phone Number",
      value: {
        name: "some_name",
        label: "Phonenumber",
        type: "text",

        rules: [{ phone_number: true }],
      },
    },
    {
      id: "regex",
      label: "Regex",
      value: {
        name: "some_name",
        label: "PAN",
        type: "text",

        rules: [
          {
            regex: {
              regex: "^[A-Za-z0-9]{10,10}$",
              message: "Please enter a valid PAN (Alphanumeric, 10 characters)",
            },
          },
        ],
      },
    },
  ],
};
