<template>
  <p :class="titleClasses">
    {{ title }}
  </p>
</template>
<script>
export default {
  name: "ContentTitle",
  props: {
    title: {
      type: String,
    },
  },
  computed: {
    titleClasses() {
      const isDark = this.isDark;
      let result = "text-h6 behavior_content_title pb-2";
      result += !isDark ? ` ${this.getFeatureValue("title.color")}--text ` : "";
      return result;
    },
  },
};
</script>
