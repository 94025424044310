<template>
  <ObjectLayout
    :aut-field-object="value.name"
    v-if="!isIgnored"
    :fields="fields"
    :attributes="displayAttributes"
    :label="label"
    :mode="mode"
    :value="value"
  >
    <template v-slot:default="slotProps">
      <Field
        class="mt-0 pt-0"
        :path="getPath(slotProps.field)"
        :definition="slotProps.field"
        :context="context"
        :index="index"
        @update="updateFieldValue(slotProps.field, $event)"
        @remove_field="removeField(slotProps.index)"
        @update_field="editField($event, slotProps.index)"
        @trigger-submit="submitForm"
      />
    </template>
  </ObjectLayout>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";

import { clone } from "@/util.js";
import ObjectLayout from "./ObjectLayout";
import definition from "./definition";

const debug = require("debug")("atman.components.object_field"); // eslint-disable-line

export default {
  name: "ObjectField",
  components: {
    Field: () => import("@/components/fields/Field"),
    ObjectLayout,
  },
  mixins: [fieldMixin],
  data() {
    return {
      definition,
      fields: clone(this?.value?.fields || []),
    };
  },
  created() {
    debug("created of Object Field", this.value);
  },
  mounted() {
    debug("mounted of Object Field", this.value);
  },

  methods: {
    markEventAsDone(eventID) {
      if (!eventID) {
        return;
      }
      this.$store.dispatch(`${this.context}/triggerAction`, {
        actionDefinition: {
          value: {
            type: "event",
            name: eventID,
          },
        },
      });
    },
    async submitForm(options) {
      const { definition, eventID } = options || {};
      const component = this;
      if (!component.displayAttributes.behave_as_form) {
        debug(`submit form being skipped`);
        this.$emit("trigger-submit", options);
        return false;
      }
      let payload;
      try {
        payload = {
          actionDefinition: definition,
          isDialog: false,
        };
        await component.$store.dispatch(
          `${component.context}/triggerAction`,
          payload
        );
      } catch (e) {
        console.error(e);
        component.displayErrorMessage(`Operation unsuccessful`, e);
      } finally {
        payload = null;
        component.markEventAsDone(eventID);
      }
    },
    getPath(field) {
      let paths = [];
      if (this.path) {
        paths.push(this.path);
      }
      if (!field.is_container) {
        paths.push(field.name);
      }
      let path = paths.join(".");
      debug(`path in object field: [${path}]`, field);
      return path;
    },
    removeField(index) {
      const component = this;
      debug(`in removeField of ${component.value.name}`, index);
      component.value.fields.splice(index, 1);
      debug(`emitting update_field`, component.value);
      component.$emit("update_field", component.value);
    },
    editField(field, index) {
      const component = this;
      debug(`in editField of ${component.value.name}`, field, index);
      component.value.fields[index] = field;
      debug(`emitting update_field`, component.value);
      component.$emit("update_field", component.value);
    },
    updateFieldValue(field, value) {
      if (!this.value.is_container) {
        if (!this.fieldValue) {
          this.fieldValue = {};
        }
        const fieldValue = clone(this.fieldValue);
        fieldValue[field.name] = value;
        debug(
          `Committing to store from non container grouped field ${field.name} - ${value}`
        );
        this.$set(this, "fieldValue", fieldValue);
      }
    },
  },
};
</script>
