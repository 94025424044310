<template>
  <div
    :class="`behavior-field-text ${dynamicClasses} ${displayClasses}`"
    v-if="!isIgnored"
  >
    <div v-if="isDisplayMode">
      <FieldLabel
        :definition="value"
        mode="display"
        :label="label"
        :attributes="labelAttributes"
      >
        <FieldDisplayValue :value="fieldValue" :definition="value" />
      </FieldLabel>
    </div>
    <v-text-field
      v-if="isInputMode"
      aut-field-value
      :name="value.name"
      v-model="effectiveValue"
      :label="label"
      @focus="onFocus"
      @blur="[_onChange($event), onChange($event)]"
      :rules="applicableRules"
      :error="hasError"
      :error-messages="errorMessage"
      :loading="loading"
      @click:clear="clearContent"
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
      :append-icon="iconToAppend"
      :hint="value.hint"
      :required="value.mandatory"
      :type="effectiveType"
      @keyup="autoConversionChange"
      @click:append="showContent = !showContent"
      v-on:keydown.enter.stop.prevent="$event.target.blur()"
    >
      <template v-slot:prepend-inner>
        <v-icon
          color="primary"
          v-on="listners"
          :aut-field-prepend-inner-icon="value.name"
          v-if="displayAttributes && displayAttributes['prepend-inner-icon']"
        >
          {{ displayAttributes["prepend-inner-icon"] }}
        </v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import { rules } from "@/util.js";

const debug = require("debug")("atman.components.text"); // eslint-disable-line

export default {
  name: "InputText",
  mixins: [fieldMixin],
  props: {
    listners: {
      type: Object,
    },
  },
  data() {
    return {
      componentRules: [],
      definition: definition,
      newValue: null,
      showContent: false,
    };
  },
  created() {
    this._type = "text";
  },
  mounted() {},
  computed: {
    effectiveMode() {
      const result =
        this?.displayAttributes?.behavior_size ||
        this?.value?.display?.mode ||
        "text";
      debug(`effectiveMode: ${result}`);
      return result;
    },
    effectiveType() {
      const defaultType = this.displayAttributes.type || "text";
      if (!this.value.masked || !this.fieldValue) {
        return defaultType;
      }

      return this.showContent ? defaultType : "password";
    },
    effectiveValue: {
      get() {
        return this.fieldValue || "";
      },
      set(value) {
        this.newValue = value;
      },
    },
    iconToAppend() {
      if (!this.value.masked) {
        return null;
      }
      return this.showContent ? "mdi-eye" : "mdi-eye-off";
    },
  },
  methods: {
    _deriveFieldSpecificRules() {
      const result = [];
      if (this.effectiveType == "number") {
        if (this.displayAttributes.min) {
          result.push(rules.min_value({ min: this.displayAttributes.min }));
        }
        if (this.displayAttributes.max) {
          result.push(rules.max_value({ max: this.displayAttributes.max }));
        }
      }
      this._componentRules = result;
    },
    autoConversionChange($event) {
      const originalValue = $event.target.value;
      const newValue = this.autoConversion($event.target.value);
      if (newValue != originalValue) {
        $event.target.value = newValue;
        $event.target.dispatchEvent(new Event("keyup"));
        debug(`Triggered change`);
      }
      this.validateInput(newValue);
    },
    onChange() {
      if (this.newValue == null) {
        return;
      }
      if (this.newValue) {
        this.newValue = this.autoConversion(this.newValue);
      }
      this.$set(this, "fieldValue", this.newValue);
      this.$emit("change", this.fieldValue);
      this.emitTypedValue();
    },
    emitTypedValue() {
      if (this.fieldValue) {
        const failures = (this.applicableRules || []).filter(
          (rule) => rule(this.fieldValue) != true
        );
        if (failures.length == 0) {
          this.$emit("typed", this.fieldValue);
        }
      }
    },
  },
};
</script>
