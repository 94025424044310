import mimeTypes from "./mimeTypes";
import file_icons from "./file_icons";
const debug = require("debug")("atman.file.mixin");

// REF: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
const imageFileTypes = [
  "apng",
  "avif",
  "jpeg",
  "gif",
  "png",
  "svg",
  "webp",
  "jpg",
];

export default {
  data() {
    const height = this?.value?.display?.embed?.height;
    const effectiveHeight = height ? `${height}px` : "100%";
    const width = this?.value?.display?.embed?.width || "120";
    debug(`effectiveHeight`, `${height || "100%"}`);
    debug(`effectiveWidth`, `${width}`);
    const effectiveWidth = `${width}px`;
    return {
      effectiveHeight,
      effectiveWidth,
    };
  },
  computed: {
    attributes() {
      return this?.value?.display?.attributes || {};
    },
    classes() {
      return this?.value?.display?.classes || [];
    },
    isMultiple() {
      return this.attributes?.multiple == true;
    },
    fileExtension() {
      const link = this.fieldValue?.type || "";
      const extension = link.substring(link.lastIndexOf(".") + 1);
      return extension;
    },
    mimeType() {
      return mimeTypes[this.fileExtension] || "";
    },
    fileType() {
      return file_icons[this.fileExtension] || "mdi-file";
    },

    isImage() {
      return imageFileTypes.includes(this.fileExtension);
    },
    supportsEmbed() {
      if (this.isFeatureEnabled("doc_in_iframe")) {
        return !this.isImage && !this.isWordDoc;
      } else {
        return !this.isImage;
      }
    },
    isWordDoc() {
      if (this.isFeatureEnabled("doc_in_iframe")) {
        return ["docx", "doc"].includes(this.fileExtension);
      } else {
        return false;
      }
    },
  },
};
