import { defaultsDeep, values } from "lodash";

const layoutFeatures = {
  "layout.left_panel.sticky": {
    label: "Make left panel sticky",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "layout.right_panel.sticky": {
    label: "Make right panel sticky",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "layout.left_panel.size": {
    label: "Left Panel size",
    hint: "Please ensure the total is less than or equal to 12",
    value: "3",
    width: "4",
  },
  "layout.main_panel.size": {
    label: "Main Panel size",
    hint: "Please ensure the total is less than or equal to 12",
    value: "12",
    width: "4",
  },
  "layout.right_panel.size": {
    label: "Right Panel size",
    hint: "Please ensure the total is less than or equal to 12",
    value: "3",
    width: "4",
  },
  "layout.two_panel.position": {
    label: "Position of panel in two-panel layout",
    value: "Left",
    options: ["Left", "Right"],
  },
};

const logoFeatures = {
  "logo.mobile.position": {
    label: "Position of Logo in a mobile device",
    value: "Sidebar",
    options: ["Header", "Sidebar"],
  },
};

const headerFeatures = {
  "mobile.header.hide_text": {
    label: "Mobile: Remove Header Text",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "header.color": {
    control: "color",
    label: "Header Color",
    value: "toolbar-color",
  },
  "header.text.color": {
    control: "color",
    label: "Header Text Color",
    value: "",
  },
  "header.menu.color": {
    control: "color",
    label: "Header Menu Color",
    value: "",
  },
  "header.user_menu.display": {
    label: "User menu in header",
    value: true,
    hint: ["Hide", "Show"],
  },
  "header.user_menu.position": {
    label: "User menu position in header",
    value: "Right",
    options: ["Left", "Right"],
  },
  "header.user_menu.background": {
    control: "color",
    label: "User menu background color",
    value: "secondary",
  },
  "header.user_menu.color": {
    control: "color",
    label: "User menu color",
    value: "white",
  },
  "header.menu.display": {
    label: "Display top level menu items in header",
    value: false,
    hint: ["Hide", "Show"],
  },
  "header.content": {
    label: "Header with: ",
    value: "Text",
    options: ["Text", "Image"],
  },
  "header.hide_during_scroll": {
    label: "Header on Scroll: ",
    value: false,
    hint: ["Show", "Hide"],
  },
  "header.display_when_unauthenticated": {
    label: "Display Header even on unauthenticated screens",
    value: true,
    hint: ["Disabled", "Enabled"],
  },
};

const notificationsFeatures = {
  "notifications.enabled": {
    label: "Notification functionality",
    value: false,
    hint: ["Disabled", "Enabled"],
  },
  "notifications.interval": {
    label: "Notification Interval",
    value: 10000,
  },
};

const appBarFeatures = {
  "appbar.display": {
    label: "Display App bar",
    value: false,
    hint: ["Hide", "Show"],
  },
  "appbar.back_button.display": {
    label: "Display Back button on App bar",
    value: false,
    hint: ["Hide", "Show"],
  },
  "appbar.application_home.display": {
    label: "Display Home button on App bar",
    value: true,
    hint: ["Hide", "Show"],
  },
  "appbar.application_home.target": {
    label: "Target of Home button",
    value: "home",
    hint: "If application is chosen, the button will only be displayed while within an application",
    options: [
      { id: "application_home", label: "Application Home" },
      { id: "home", label: "Home" },
    ],
  },
  "appbar.menu_button.display": {
    label: "Display Menu Button on App bar",
    value: true,
    hint: ["Hide", "Show"],
  },
  "appbar.back_button.color": {
    label: "color of Back button on App bar",
    value: "primary",
    control: "color",
  },
  "appbar.application_home.color": {
    label: "color of Home button on App bar",
    value: "primary",
    control: "color",
  },
  "appbar.menu_button.color": {
    label: "color of Menu Button on App bar",
    value: "primary",
    control: "color",
  },
};

const sidebarFeatures = {
  "mobile.sidebar.position": {
    label: "Mobile: Position of Sidebar",
    value: "Bottom",
    options: ["Side", "Bottom"],
  },
  "sidebar.user_menu.display": {
    label: "User menu in sidebar",
    value: false,
    hint: ["Hide", "Show"],
  },
  "sidebar.mini_variant": {
    label: "Display the mini-variation of the Sidebar menu",
    value: false,
    hint: ["False", "True"],
  },
  "sidebar.sidebar_variant": {
    label: "Display the mini version of Sidebar accompanied with text",
    value: false,
    hint: ["False", "True"],
  },
};

const componentFeatures = {
  "form.default_field_width": {
    label: "Form field width default value",
    value: "12",
    options: ["4", "6", "12"],
  },
  "form.submit_button.disable_when_invalid": {
    label: "Submit button behavior when form is invalid",
    value: false,
    hint: ["Enabled", "Disabled"],
  },
  "form.strip_system_fields_during_submit": {
    label: "Strip system fields during submission",
    value: true,
    hint: ["Disabled", "Enabled"],
  },
  "form.actions.justification": {
    label: "Form action justification",
    value: "Right",
    options: ["Right", "Center", "Left"],
  },
  "list.overlay.enabled": {
    label: "Display an overlay during search",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "refresh_interval.enabled": {
    label: "Support refresh interval",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "title.color": {
    control: "color",
    label: "Select a color for Form/List titles",
    value: "primary",
  },
  "search.advanced_search.add_wild_cards": {
    label: "Add wild cards",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "cards.image.height": {
    label: "Image max height in list cards",
    value: "300px",
  },
  "cards.number_to_render": {
    label: "How many cards to render at one go when rendering a list",
    value: 6,
  },
};

const editorFeatures = {
  "editor.actions.position": {
    value: false,
    label: "Position of editor icons",
    hint: ["Right", "Left"],
  },

  "editor.abort_fetch_during_design": {
    label: "Abort fetch requests when in design mode",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "editor.page_creation.allow_path_modification": {
    label: "Allow user to modify path during page creation",
    value: true,
    hint: ["Don't allow", "Allow"],
  },
  "editor.page_creation.entity_mandatory": {
    label: "Entity is mandatory during page creation",
    value: false,
    hint: ["Not Mandatory", "Mandatory"],
  },
  "editor.templates.show_tutorials": {
    label: "Show templates tagged as 'tutorial' when adding fields",
    value: true,
    hint: ["No", "Yes"],
  },
  "editor.mobile.display": {
    value: false,
    label: "Display Editor actions in mobile form factor",
    hint: ["No", "Yes"],
  },
};

const requestOptimisation = {
  "cache.enabled": {
    label: "Caching requests & responses",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "cache.interval": {
    label: "Caching interval (seconds)",
    value: "60",
  },
  "stagger_requests.enabled": {
    label: "Stagger duplicate requests",
    value: true,
    hint: ["Disable", "Enable"],
  },
};

const dialogFeatures = {
  "dialog.close_button.display": {
    label: "Display close icon on dialogs",
    value: false,
    hint: ["Off", "On"],
  },
  "dialog.close_button.mobile..display": {
    label: "Display close icon on dialogs (Mobile)",
    value: true,
    hint: ["Off", "On"],
  },
};

const feedbackFeatures = {
  "feedback.enabled": {
    label: "Show Feedback",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "feedback.target": {
    label: "Feedback Page",
    value: "",
    control: "page",
  },
};

const faqFeatures = {
  "faq.enabled": {
    label: "Show FAQ",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "faq.target": {
    label: "FAQ Page",
    value: "",
    control: "page",
  },
};

const pinningFeatures = {
  "dynamic_pages.allow_target": {
    label: "Allow user to choose specific target URLs when pinning",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "dynamic_pages.skeleton_loader": {
    label: "Skeleton Loader on Load",
    value: false,
    hint: ["Dont't Show", "Show"],
  },
};

const favoriteFeatures = {
  "favourite.enabled": {
    label: "Favourite Functionality",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "favourite.show_dialog": {
    label: "Show Dialog when saving favourites",
    value: true,
    hint: ["Disable", "Enable"],
  },
};

const features = {
  "advanced.post_moderation": {
    label: "Post Moderation",
    value: true,
    hint: ["Off", "On"],
  },
  "advanced.post.edit.enabled": {
    label: "Edit Post",
    value: true,
    hint: ["Off", "On"],
  },

  "binaries.display_mode": {
    label: "Binaries display mode",
    value: "object",
    options: ["embed", "object"],
  },

  "response.file_handling": {
    label: "handle files separately",
    value: true,
    hint: ["No", "Yes"],
  },
};

const permissionsFeatures = {
  "permissions.augment_permissions_from_role": {
    label:
      "Overrides access_control with the permissions configured for the user's role",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "permissions.check_route_permissions": {
    label: "Check route permissions",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "permissions.non_editor.enabled": {
    label: "Support Permissions Management when not within the editor",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "permissions.bypass_roles": {
    label: "Bypass permission checks for the roles",
    value: "admin,sysadmin",
  },
  "permissions.field.default_behavior": {
    label: "Default behavior if permission is not available for field",
    value: "hidden",
    options: ["hidden", "disabled"],
  },
  "permissions.menu.default_behavior": {
    label: "Default behavior if permission is not available for menu",
    value: "hidden",
    options: ["hidden", "disabled"],
  },
};

const termsAndConditionsFeatures = {
  "terms_and_conditions.enabled": {
    label: "Show Terms & Conditions",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "terms_and_conditions.content": {
    label: "Terms & Conditions (Content)",
    control: "textarea",
    value: "",
  },
};

const systemPages = {
  "/login": {
    custom_page: "login_page",
    label: "Login Page",
    key: "login",
    behavior: "replace",
  },
  "/register": {
    custom_page: "registration_page",
    label: "Registration Page",
    key: "registration",
    behavior: "replace",
  },
  "/reset_password": {
    custom_page: "reset_password_page",
    label: "Reset Password Page",
    key: "reset_password",
    behavior: "replace",
  },
  "/me": {
    custom_page: "profile_page",
    label: "Profile Page",
    key: "profile",
    behavior: "replace",
  },
  "/": {
    custom_page: "dashboard_page",
    label: "Dashboard Page",
    key: "",
    behavior: "redirect",
  },
  // HACK to ensure / and /dashboard are treated the same
  "/dashboard": {
    custom_page: "dashboard_page",
    label: "Dashboard Page",
    key: "",
    behavior: "redirect",
  },
};

let custom_pages = {};
values(systemPages).forEach((value) => {
  custom_pages[`custom_pages.${value.custom_page}`] = {
    label: value.label,
    value: "",
    control: "page",
  };
});

const applicationFeatures = {
  "application_creation.templates.enabled": {
    label: "Allow template selection during application creation",
    value: false,
    hint: ["No", "Yes"],
  },
  "application_creation.file_upload.enabled": {
    label: "Allow file upload during application creation",
    value: false,
    hint: ["No", "Yes"],
  },
  "application_creation.wizard.enabled": {
    label: "Allow Wizard during application creation",
    value: true,
    hint: ["No", "Yes"],
  },
  "application_creation.home_page.create": {
    label: "Create home Page implicitly",
    value: true,
    hint: ["No", "Yes"],
  },
  "application_creation.show_on_first_login": {
    label: "Show application creation on first login",
    value: false,
    hint: ["No", "Yes"],
  },
};

const welcomeFeatures = {
  "welcome_page.enabled": {
    label: "Show Welcome Page",
    value: false,
    hint: ["Disable", "Enable"],
  },
  "welcome_message.enabled": {
    label: "Show Welcome Message",
    value: true,
    hint: ["Disable", "Enable"],
  },
  "welcome_page.target": {
    label: "Welcome Page",
    control: "page",
    value: "",
  },
};

const allFeatures = defaultsDeep(
  {},
  welcomeFeatures,
  requestOptimisation,
  dialogFeatures,
  editorFeatures,
  headerFeatures,
  applicationFeatures,
  sidebarFeatures,
  appBarFeatures,
  features,
  custom_pages,
  layoutFeatures,
  termsAndConditionsFeatures,
  permissionsFeatures,
  componentFeatures,
  notificationsFeatures,
  faqFeatures,
  feedbackFeatures,
  favoriteFeatures,
  pinningFeatures,
  logoFeatures
);

const featureCategories = [
  {
    id: "appBarFeatures",
    label: "Appbar",
    features: appBarFeatures,
  },
  {
    id: "application",
    label: "Application",
    features: applicationFeatures,
  },
  {
    id: "advanced",
    label: "Advanced",
    features: features,
  },
  {
    id: "custom_pages",
    label: "Custom Pages",
    features: custom_pages,
  },
  {
    id: "dialogs",
    label: "Dialogs",
    features: dialogFeatures,
  },
  {
    id: "editor",
    label: "Editor",
    features: editorFeatures,
  },
  {
    id: "faqFeatures",
    label: "FAQ",
    features: faqFeatures,
  },
  {
    id: "feedbackFeatures",
    label: "Feedback",
    features: feedbackFeatures,
  },
  {
    id: "favoriteFeatures",
    label: "Favourite",
    features: favoriteFeatures,
  },

  {
    id: "componentFeatures",
    label: "Form/List",
    features: componentFeatures,
  },
  {
    id: "permissions",
    label: "Permissions",
    features: permissionsFeatures,
  },
  {
    id: "pinningFeatures",
    label: "Page Pinning",
    features: pinningFeatures,
  },
  {
    id: "request_optimisation",
    label: "Requests",
    features: requestOptimisation,
  },
  {
    id: "notifications",
    label: "Notifications",
    features: notificationsFeatures,
  },
  {
    id: "t_and_c",
    label: "T&C",
    features: termsAndConditionsFeatures,
  },
  {
    id: "welcome",
    label: "Welcome",
    features: welcomeFeatures,
  },
];

export {
  editorFeatures,
  componentFeatures,
  permissionsFeatures,
  termsAndConditionsFeatures,
  featureCategories,
  systemPages,
  applicationFeatures,
  features,
  headerFeatures,
  sidebarFeatures,
  appBarFeatures,
  allFeatures,
  custom_pages,
  layoutFeatures,
  logoFeatures,
};
