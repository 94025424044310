export default {
  label: "",
  type: "table",
  definition: {
    definition: {
      actions: null,
      fields: [
        {
          display: {
            embed: {
              width: "15",
            },
            width: "4",
          },
          label: "Image",
          mode: "display",
          name: "product.images.[0]._file",
          type: "image",
        },
        {
          display: {
            width: "1",
          },
          mode: "display",
          type: "spacer",
        },
        {
          display: {
            width: "6",
          },
          fields: [
            {
              display: {
                attributes: {
                  label: {
                    display: "hidden",
                  },
                  long_text: "ellipsis",
                },
                classes: ["font-weight-bold", "primary--text"],
                width: 12,
              },
              label: "",
              mode: "display",
              name: "product.name",
              type: "text",
            },
            {
              display: {
                attributes: {
                  label: {
                    classes: ["font-weight-bold"],
                  },
                },
                block: true,
                classes: ["behaviour_metadata", "font-weight-medium"],
                width: 12,
              },
              is_dynamic: true,
              label: "Cost",
              mode: "display",
              name: "item_cost",
              rule: {
                cost_per_item: "product.price",
                operation: "ITEM_COST",
                quantity: "quantity",
              },
              type: "cost",
            },
            {
              display: {
                attributes: {
                  dense: true,
                  height: 20,
                  "hide-details": true,
                  rounded: false,
                },
                classes: ["font-weight-medium", "mt-1"],
                width: 12,
              },
              label: "Quantity",
              mode: "input",
              name: "quantity",
              type: "text",
              apis: {
                submit: {
                  type: "event",
                  name: "update_list_item",
                  params: {
                    quantity: "{_row->quantity}",
                    id: "{_row->product->id}",
                  },
                },
              },
            },
          ],
          is_container: true,
          label: "",
          name: "cart_details",
          type: "object",
        },
        {
          display: {
            classes: ["mb-3", "mt-1"],
            width: 12,
          },
          fields: [
            {
              confirmation: "Are you sure?",
              display: {
                attributes: {
                  color: "error",
                  text: true,
                },
                width: 12,
              },
              hint: "Remove Item",
              icon: "mdi-delete",
              label: "Remove",
              name: "delete",
              type: "action",
              value: {
                type: "event",
                name: "delete_list_item",
                params: {
                  id: "{_row->id}",
                },
              },
            },
          ],
          is_container: true,
          type: "object",
        },
      ],
      footers: [
        {
          type: "label",
          name: "total_label",
          value: "Total",
        },
        {
          label: "",
          mode: "display",
          is_dynamic: true,
          depends_on: "[[0-9]*].quantity", // eslint-disable-line no-useless-escape
          rule: {
            operation: "TOTAL",
            field: "quantity",
          },
          name: "total_quantity",
          type: "label",
        },
        {
          type: "spacer",
          name: "spacer",
        },
        {
          label: "",
          mode: "display",
          is_dynamic: true,
          depends_on: "[[0-9]*].product.price", // eslint-disable-line no-useless-escape
          rule: {
            operation: "TOTAL",
            field: "product.price",
          },
          name: "total_cost",
          type: "cost",
        },
        {
          type: "spacer",
          name: "spacer",
        },
      ],
      mode: "display",
    },
    display: {
      attributes: {
        cards: {
          classes: ["ml-n2", "mt-n4"],
          outlined: true,
          width: "12",
        },
        "disable-filtering": true,
        flat: true,
      },
      classes: ["transparent"],
      mode: "cards",
      width: "12",
    },
    id: "list",
    title: "",
    type: "list",
  },
};
