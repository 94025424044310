<template>
  <div v-if="!isDisplayMode" aut-questionnaire-input>
    <div
      v-for="(questionContent, i) in questions"
      :key="`${i}-${questionContent.id}`"
    >
      <v-card
        class="mb-2"
        v-if="!questionContent.hidden"
        v-bind="questionCardAttributes(questionContent)"
      >
        <v-card-title class="mb-0 pb-0">
          <p>{{ questionContent.category.label }}</p>
        </v-card-title>
        <v-card-text :aut-question="questionContent.id">
          <p>{{ questionContent.question }}</p>
          <v-radio-group
            aut-answer-options
            v-model="answer"
            dense
            class="mt-0"
            row
            @change="handleAnswer(questionContent)"
          >
            <v-radio
              :aut-answer="option.id"
              :disabled="questionContent.disabled"
              aut-questionaire-radio
              v-for="(option, i) in questionContent.answers"
              :key="i"
              :label="option.name"
              :value="option.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </div>
  </div>
  <div v-else aut-questionaire-summary>
    <h2 class="mb-2">Summary</h2>
    <div
      v-for="(questionContent, i) in summaryContent"
      :key="`${i}-${questionContent.id}`"
    >
      <v-card
        class="mb-2"
        :aut-question="questionContent.id"
        v-bind="questionCardAttributes(questionContent)"
      >
        <v-card-title class="mb-0 pb-0">
          <p class="my-0 py-0" aut-question-description>
            <!-- <span  class="text-subtitle-2 primary--text"
              >Category:</span
            > -->
            {{ questionContent.category.label }}
          </p>
        </v-card-title>
        <v-card-text>
          <p class="my-0 py-0">
            <span class="text-subtitle-2 primary--text">Question:</span>
            {{ questionContent.question }}
          </p>
          <p class="my-0 py-0">
            <span
              :aut-answer="questionContent.answer.id"
              class="text-subtitle-2 primary--text"
              >Answer:</span
            >
            {{ questionContent.answer.name }}
          </p>
        </v-card-text>
      </v-card>
    </div>
    <div>
      <v-card elevated outlined aut-questionaire-result>
        <v-card-title class="primary white--text" v-if="summaryTitle">{{
          summaryTitle
        }}</v-card-title>
        <v-card-text class="primary white--text" aut-questionaire-outcome>{{
          outcome.label
        }}</v-card-text>
      </v-card>
    </div>
    <div v-if="value.mode == 'input'">
      <v-btn block aut-reset-questionaire @click="resetQuestionaire"
        >Redo</v-btn
      >
    </div>
  </div>
</template>

<script>
import { Engine } from "json-rules-engine";
import { forOwn } from "lodash";
import { clone } from "@/util";
import { fieldMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.fields.questionaire"); // eslint-disable-line

export default {
  name: "QuestionaireField",
  mixins: [fieldMixin],
  data() {
    return {
      events: [],
      answer: null,
      questions: [],
    };
  },

  computed: {
    summaryTitle() {
      return this?.value?.definition?.summary?.title;
    },
    outcome() {
      return this.fieldValue?.outcome || {};
    },
    summaryStats() {
      const result = {};
      (this.summaryContent || []).reduce((accumulator, item) => {
        // debug(`accumulator`, accumulator, `item`,item);
        accumulator[item.category.id] = accumulator[item.category.id] || {};

        accumulator[item.category.id]["all"] =
          (accumulator[item.category.id]["all"] || 0) + 1;

        accumulator[item.category.id][item.answer.id] =
          (accumulator[item.category.id][item.answer.id] || 0) + 1;
        return accumulator;
      }, result);
      debug("summaryStats", result);
      return result;
    },
    summaryContent() {
      let result = [];
      forOwn(this.fieldValue?.responses || {}, (answerID, questionID) => {
        const questionContent = this.questions.find(
          ({ id }) => id == questionID
        );
        if (!questionContent) {
          return;
        }
        const answerContent = questionContent.answers.find(
          ({ id }) => id == answerID
        );
        if (!answerContent) {
          return;
        }
        result.push({
          category: questionContent.category,
          question: questionContent.question,
          id: questionContent.id,
          answer: answerContent,
        });
      });
      debug(`summaryContent`, result);
      return result;
    },
  },
  mounted() {
    debug(`fieldValue`, this.value);
    this.prepare();
  },
  methods: {
    questionCardAttributes(questionContent) {
      const category =
        this?.value?.definition?.categories?.[questionContent?.category?.id];
      const result = category?.display?.attributes || {};
      debug(`questionCardAttributes`, questionContent, category, result);
      return result;
    },
    prepare() {
      let events = [];
      let questions = [];
      const categories = this.value?.definition?.categories || {};
      forOwn(categories, (categoryContent, categoryId) => {
        const categoryEvents = categoryContent.events;
        forOwn(categoryEvents, (eventContent, id) => {
          const event = clone(eventContent);
          event.category = {
            label: categoryContent.label,
            id: categoryId,
          };
          event.id = id;
          events.push(event);
          if (event.type == "display_question") {
            questions.push(Object.assign({ id }, event, { hidden: true }));
          }
        });
      });
      forOwn(this.value.definition.events, (eventContent, id) => {
        const event = clone(eventContent);
        event.id = id;
        events.push(event);
      });
      this.events = [...clone(events)];
      this.questions = clone(questions);
      debug(`events`, events);
      debug(`questions`, questions);
      if (this.value.mode == "input") {
        this.fieldValue = {};
        this.processEvent(this.value.definition.start);
      }
    },
    resetQuestionaire() {
      this.mode = "input";
      this.prepare();
    },
    displaySummary() {
      this.mode = "display";
    },
    async processEvent(event) {
      const component = this;
      if (!event) {
        component.displaySummary();
        return true;
      }
      debug(`results`, event);
      switch (event.type) {
        case "trigger_event": {
          const ids = event?.params?.id;
          if (Array.isArray(ids)) {
            while (ids.length) {
              let id = ids.shift();
              component.triggerEvent(id);
            }
          } else if (typeof ids == "string") {
            debug(`triggering event`, ids);
            component.triggerEvent(ids);
          }
          break;
        }
        case "display_message": {
          debug(`displaying message`, event.params.id);
          const message = event.params.message;
          component.displayInfoMessage(message);
          break;
        }
        case "assign": {
          debug(`assigning value`, event.params.id);
          const params = event.params;
          component.$set(component.fieldValue, params.target, params.value);
          break;
        }
      }
    },
    async triggerEvent(eventID) {
      debug(`triggerEvent`, eventID);
      const component = this;
      const eventDetails = this.events.find((event) => event.id == eventID);
      if (!eventDetails) {
        return;
      }
      switch (eventDetails.type) {
        case "display_question": {
          const nextQuestionID = eventDetails.id;
          component.showQuestion(nextQuestionID);
          break;
        }
        case "display_summary": {
          debug(`In display Summary`, eventDetails);
          const events = await component.processRules(eventDetails.rules);
          await this.processEvents(events);
          this.displaySummary();
          break;
        }
        default: {
          debug(`In default`, eventDetails);
        }
      }
    },
    async processEvents(events) {
      if (events.length == 0) {
        return;
      }
      const component = this;
      let event = events.shift();
      await component.processEvent(event);
      if (event.params?._is_exclusive) {
        return;
      }
      component.processEvents(events);
    },
    async processRules(rules) {
      const component = this;
      const inputs = {
        questionnaire: component.fieldValue?.responses || {},
        summary: component.summaryContent,
        stats: component.summaryStats,
      };
      debug(`Inputs`, inputs);
      const engine = new Engine(rules);
      const results = await engine.run(inputs);
      return results.events || [];
    },
    async handleAnswer(answeredQuestion) {
      debug(`in handleAnswer`, answeredQuestion, this.answer);

      const answerContent = answeredQuestion.answers.find(
        (answerContent) => answerContent.id == this.answer
      );

      const fieldValue = clone(this.fieldValue || {});
      fieldValue.responses = fieldValue.responses || {};
      fieldValue.responses[answeredQuestion.id] = this.answer;
      this.fieldValue = fieldValue;

      this.answer = null;

      if (this.displayAttributes?.answered_questions == "hide") {
        this.hideQuestion(answeredQuestion);
      } else {
        this.disableQuestion(answeredQuestion);
      }
      let events = [];

      // IF the answer has some rules, process them and handle any events
      if (answerContent.rules) {
        debug(`answer has rules, processing them`, answerContent.rules);
        events = await this.processRules(answerContent.rules);
        if (events.length) {
          debug(`events returned from rules`, events);
          return this.processEvents(events);
        }
      }

      // IF the answer has a fallback next, process those events
      events = answerContent.next || [];
      if (events.length) {
        debug(`answer has next events, processing them`, events);
        return this.processEvents(events);
      }

      // IF the question has some rules, process them and handle any events
      if (answeredQuestion.rules) {
        debug(`question has rules, processing them`, answeredQuestion.rules);
        events = await this.processRules(answeredQuestion.rules);
        if (events.length) {
          debug(`events returned from rules`, events);
          return this.processEvents(events);
        }
      }

      // IF the question a fallback event, process those events
      events = answeredQuestion.next || [];
      if (events.length) {
        debug(`question has next events, processing them`, events);
        return this.processEvents(events);
      }
    },
    showQuestion(questionID) {
      debug(`showQuestion`, questionID);
      const question = this.questions.find((questionContent) => {
        return questionContent.id == questionID;
      });
      if (!question) {
        console.error(`Question [${questionID}] is missing`);
        return;
      }
      question.hidden = false;
      debug(`question`, question);
    },
    disableQuestion(question) {
      question.disabled = true;
    },
    hideQuestion(question) {
      question.hidden = true;
    },
  },
};
</script>
