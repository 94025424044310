<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <AtmanComponent
      :context="listContext"
      :definition="listDefinition"
      :data="fieldValue"
    />
  </div>
</template>
<script>
import { store } from "@/components/fields/store";
import { fieldMixin } from "@/components/mixin.js";
import { clone, uniqueID } from "@/util.js";
const debug = require("debug")("atman.components.list_field"); // eslint-disable-line

export default {
  name: "ListField",
  mixins: [fieldMixin],
  components: {
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
  },
  computed: {
    listContext() {
      const result = this.$store?.state?.[this.context]?.context || {};
      debug(`listContext`, result);
      return result;
    },
    listDefinition() {
      const result = Object.assign(
        {
          type: "list",
        },
        clone(this.value?.definition || {})
      );
      debug(`list definition`, result);
      return result;
    },
  },
  created() {
    const component = this;
    const storeKey = (this.storeKey = `table_field_${
      this.value?.name || uniqueID()
    }`);
    component._createStore(storeKey, store);
  },
  mounted() {
    debug(`fieldValue`, this.fieldValue);
  },
};
</script>
