import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import ReadBook from "@/views/ReadBook/ReadBook.vue";
import APIHandler from "@/views/APIHandler.vue";
import UnderConstruction from "@/views/UnderConstruction.vue";

import user from "@/store/user";

import applicationRoutes from "@/views/applications/routes.js";
import formRoutes from "@/views/forms/routes.js";
import userRoutes from "@/views/user/routes.js";
import administrationRoutes from "@/views/administration/routes.js";
import { SHOW_DIALOG } from "@/constants";
import { isAnUnauthenticatedPath } from "./util";

const debug = require("debug")("atman.router");

Vue.use(VueRouter);

const routes = [
  {
    path: "*", //Always go to this page regardless of the url
    name: "Home",
    component: Home,
    meta: { unauthenticated: false },
  },
  {
    path: "*read_book*", //Always go to this page regardless of the url
    name: "ReadBook",
    component: ReadBook,
    meta: { unauthenticated: false },
  },
  {
    path: "/under_construction", //Always go to this page regardless of the url
    name: "UnderConstruction",
    component: UnderConstruction,
    meta: { unauthenticated: false },
  },
  ...applicationRoutes,
  ...administrationRoutes,
  ...userRoutes,
  ...formRoutes,
];

let apiEndPoint = process.env.NODE_ENV != "development" ? "api" : "apis";
routes.push({
  path: `/${apiEndPoint}/:endpoint*`, //API redirection
  name: "API",
  component: APIHandler,
  meta: { unauthenticated: true },
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  debug("Router navigation", from, to);
  // Store the earlier path. Useful for navigating back
  localStorage.from_path = from.fullPath;
  let routeHandler = undefined; //IMPORTANT. Don't change to null

  const recordDoesNotNeedAuth =
    to.matched.some((record) => record.meta.unauthenticated) ||
    isAnUnauthenticatedPath(to.fullPath);

  const profileIsNotAvailable = Object.keys(user?.state?.profile).length == 0;
  const tokenIsNotAvailable = !user?.state?.profile?.auth_token;

  debug(`recordDoesNotNeedAuth: [${recordDoesNotNeedAuth}], profileIsNotAvailable: [${profileIsNotAvailable}]
  tokenIsNotAvailable: [${tokenIsNotAvailable}]`);
  if (!profileIsNotAvailable && tokenIsNotAvailable) {
    debug(
      `unhandled flow. token is not available in profile`,
      user?.state?.profile
    );
  }
  if (profileIsNotAvailable && !recordDoesNotNeedAuth) {
    debug(
      `profile is not available and route needs auth. redirecting to login`,
      to
    );
    routeHandler = {
      path: "/login",
      query: { redirect: to.fullPath },
      props: { sidebar: true },
    };
  }

  if (to.fullPath == "/" || to.fullPath == "/dashboard") {
    let userRole = user?.state?.profile?.default_profile?.role_id;
    if (userRole) {
      const defaultPageFn = window.vue?.$store?.getters?.defaultPage;
      if (typeof defaultPageFn == "function") {
        const defaultPage = defaultPageFn(userRole);
        debug(`defaultPage for user`, defaultPage);
        if (defaultPage && defaultPage != "/" && defaultPage != "/dashboard") {
          routeHandler = {
            path: defaultPage,
          };
        }
      }
    }
  }

  let path = to.fullPath;
  if (path.indexOf("dialog=") != -1) {
    path = path.replace("dialog=", "page=");
    debug(`user attempting to navigate to dialog`);
    next(false);
    setTimeout(() => {
      window.vue.$store.dispatch(SHOW_DIALOG, { url: path });
    }, 1000);
    return;
  }
  next(routeHandler);
});

export default router;
