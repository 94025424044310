<template>
  <FieldLabel
    v-if="formattedValue"
    :definition="value"
    mode="display"
    :label="label"
    :attributes="labelAttributes"
  >
    <span v-bind="displayAttributes" :class="displayClasses" aut-field-value>
      {{ formattedValue }}
    </span>
  </FieldLabel>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import { determineFormat } from "./util";
import {
  isBefore,
  intervalToDuration,
  parseISO,
  formatDuration,
} from "date-fns";
const debug = require("debug")("atman.components.count_down"); // eslint-disable-line

export default {
  mixins: [fieldMixin],
  name: "CountDownClock",
  mounted() {
    debug("mounted of CountDownClock", this.value);
    this.setFormattedValue();
  },
  data() {
    return {
      formattedValue: "",
      abortCountdown: false,
    };
  },
  beforeDestroy() {
    if (this.refreshTimeoutID) {
      clearTimeout(this.refreshTimeoutID);
    }
  },
  methods: {
    getDuration() {
      if (!this.fieldValue) {
        return "";
      }
      const start = new Date();
      const end = new Date(parseISO(this.fieldValue));

      const result = intervalToDuration({
        start,
        end,
      });
      return result;
    },

    getFormat() {
      const duration = this.getDuration();
      if (!duration) {
        return [];
      }
      let result;
      if (this.value.format) {
        result = this.value.format.split(" ");
      } else {
        result = determineFormat(duration);
      }
      return result;
    },
    setFormattedValue() {
      if (this.abortCountdown) {
        return;
      }
      const duration = this.getDuration();
      const format = this.getFormat();
      if (duration) {
        this.formattedValue = "";
        this.$nextTick(() => {
          const start = new Date();
          const end = new Date(parseISO(this.fieldValue));
          if (!this.isInLimit(start, end)) {
            this.abortCountdown = true;
            return;
          }
          const formattedDuration = formatDuration(duration, { format });
          const pastLabel = this.displayAttributes?.past_label || "ago";
          const futureLabel = this.displayAttributes?.future_label || "";
          const suffix = isBefore(end, start) ? pastLabel : futureLabel;
          this.formattedValue = `${formattedDuration} ${suffix}`;
        });
      }
      this.setupRefresh();
    },
    setupRefresh() {
      if (this.abortCountdown) {
        return;
      }
      let interval = 0;
      const format = this.getFormat();
      if (format.includes("seconds")) {
        interval = 1;
      } else if (format.includes("minutes")) {
        interval = 60;
      } else if (format.includes("hours")) {
        interval = 60 * 60;
      }
      if (!interval) {
        return;
      }
      this.refreshTimeoutID = setTimeout(() => {
        this.setFormattedValue();
      }, interval * 1000);
    },
    isInLimit(start, end) {
      const limit = this.displayAttributes?.limit;

      if (!limit) {
        return true;
      }

      const isPastTime = isBefore(end, start);

      if (limit == "future") {
        return !isPastTime;
      }

      if (limit == "past") {
        return isPastTime;
      }

      return true;
    },
  },
};
</script>
