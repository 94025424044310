<template>
  <div>
    <v-tooltip top v-if="isInputMode">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="float-right mt-n2"
          small
          v-bind="attrs"
          v-on="on"
          @click="addArrayItem"
          aut-add-array-item
          text
        >
          Add</v-btn
        >
      </template>
      Add an item
    </v-tooltip>
    <ArrayFieldLayout
      :value="value"
      :items="arrayItems"
      :arrayKey="refreshKey"
      @update:items="fieldValue = $event"
    >
      <template v-slot:default="slotProps">
        <ArrayFieldItem
          :value="slotProps.item"
          :aut-array-item="slotProps.index"
          :definition="value"
          :path="path"
          :index="slotProps.index"
          :context="context"
          @remove="removeItem(slotProps.index)"
        />
      </template>
    </ArrayFieldLayout>
  </div>
</template>
<script>
import { clone } from "@/util";
import { fieldMixin } from "@/components/mixin.js";
import ArrayFieldItem from "./ArrayFieldItem.vue";
import ArrayFieldLayout from "./ArrayFieldLayout.vue";

const debug = require("debug")("atman.components.field.array");
debug("array_field");
export default {
  name: "ArrayField",
  components: {
    ArrayFieldItem,
    ArrayFieldLayout,
  },
  mixins: [fieldMixin],
  data() {
    return {
      refreshKey: 1,
    };
  },
  computed: {
    field() {
      return clone(this.value.field);
    },
    arrayItems() {
      return this.fieldValue;
    },
  },
  mounted() {
    debug("mounted of Array Field", this.value);
  },
  methods: {
    getHoverClass(hover) {
      if (this.isInputMode && hover) {
        return "behaviour_hover elevation-1";
      }
      return "";
    },
    addArrayItem() {
      const fieldValue = [
        ...(this.value?.value || []),
        this.field.default_value,
      ];
      this.fieldValue = fieldValue;
      debug(`this.fieldValue`, this.fieldValue);
      this.refreshKey++;
    },
    removeItem(index) {
      this.fieldValue.splice(index, 1);
      this.refreshKey++;
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border: 1px solid black;
}
</style>
