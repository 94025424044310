<template>
  <!-- @change="emitUpdate" -->
  <v-combobox
    ref="itemsCombobox"
    :aut-combo-field="definition.name"
    :search-input.sync="search"
    @blur="emitUpdate"
    item-text="name"
    item-value="id"
    :items="items"
    v-model="model"
    :rules="applicableRules"
    :error="errorState"
    :error-messages="errorMessages"
    :filter="filter"
    :label="label"
    :hide-no-data="!search"
    :multiple="isMultiple"
    :hint="definition.hint"
    v-bind="attributes"
    v-bind:class="classes"
    @keydown.enter.stop="adder"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            {{ item.name || item }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:no-data>
      <v-list-item v-if="isValid(search) && addMessage">
        <span class="aut-dropdown-search-message subheading">{{
          addMessage
        }}</span>
      </v-list-item>
    </template>
    <template
      v-slot:selection="{ item, index }"
      v-if="definition.multiple && attributes.atman_truncate"
    >
      <v-chip small v-if="index === 0">
        <span v-if="fieldValue">
          {{ item.name }}
          {{ fieldValue.length > 1 ? " + " + (fieldValue.length - 1) : "" }}
        </span>
      </v-chip>
    </template>
    <template v-slot:append v-if="appendAttributes.icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            color="primary"
            @click.stop="appendIconAction"
          >
            {{ appendAttributes.icon }}
          </v-icon>
        </template>
        {{ appendAttributes.hint }}
      </v-tooltip>
    </template>
  </v-combobox>
</template>
<script>
import { getRuntimeRules, rules } from "@/rules";
import { uniqWith, isEqual, isPlainObject } from "lodash";
import dropdownMixin from "./mixin.js";
const debug = require("debug")("atman.field.dropdown.combobox"); // eslint-disable-line

const defaultRules = [
  {
    regex: {
      message: "Please enter at least some alphanumeric characters",
      regex: "^[A-Za-z0-9]{1,}.*$",
    },
  },
];

const performValidations = (newItem = "", rulesInField) => {
  const failures = [];
  const effectiveRules = [...rulesInField, ...defaultRules];
  effectiveRules.forEach((rule) => {
    const ruleName = Object.keys(rule)[0];
    const ruleConfig = rule[ruleName];
    let ruleOutcome = rules[ruleName](ruleConfig)(newItem);
    if (ruleOutcome !== true) {
      failures.push(ruleOutcome);
    }
  });
  return failures;
};

const getNewItems = (comboBoxEntries, validator) => {
  let actualModels = [];
  comboBoxEntries.forEach((newItem) => {
    if (typeof newItem != "string") {
      actualModels.push(newItem);
    }
    const outcome = validator(newItem);
    if (outcome) {
      actualModels.push(newItem);
    }
  });
  actualModels = uniqWith(actualModels, (val1, val2) => {
    return isEqual(val1, val2);
  });
  return actualModels;
};

export default {
  name: "DropdownCombobox",
  mixins: [dropdownMixin],
  watch: {
    model: function (val, prev) {
      const methodDebug = debug.extend("watch_model"); //eslint-disable-line
      methodDebug(`model changed`, val, prev);
      // If nothing new is added, leave as is.
      const prevLength = prev?.length || 0;
      const currentLength = val?.length || 0;
      if (currentLength === prevLength) return;
      // Iterate through the items
      this.updateFromComboValues(val);
    },
    items() {
      this.updateModel();
    },
    fieldValue() {
      this.updateModel();
    },
  },
  data() {
    let rules = this.rules || [];
    rules = [...rules, ...getRuntimeRules(defaultRules)];
    return {
      addMessage: "",
      errorMessages: "",
      errorState: false,
      applicableRules: rules,
      search: null,
      model: this.definition.multiple ? [] : "",
    };
  },
  computed: {
    isMultiple() {
      const methodDebug = debug.extend("computed_isMultiple"); //eslint-disable-line
      const result = this.definition.multiple;
      methodDebug(`isMultiple`, result);
      return result;
    },
  },
  methods: {
    filter(item, queryText, itemText) {
      const methodDebug = debug.extend("filter"); //eslint-disable-line
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    emitUpdate() {
      const methodDebug = debug.extend("emitUpdate"); //eslint-disable-line
      methodDebug(
        `Emitting update`,
        this.fieldValue,
        Array.isArray(this.fieldValue) && this.fieldValue.length
      );
      if (
        this.isMultiple &&
        this.definition.mandatory &&
        !this.fieldValue?.length
      ) {
        this.errorState = true;
        this.errorMessages = "This field is mandatory";
        return;
      }
      this.$emit("update", this.fieldValue);
    },
    isValid(search = "") {
      const methodDebug = debug.extend("isValid"); //eslint-disable-line
      const failures = performValidations(
        search,
        this.attributes?.other?.rules || []
      );
      let addMessage = "";
      if (failures.length) {
        addMessage = failures.join(", ");
      } else if (search && search.trim()) {
        addMessage = `Press Enter to add ${search} ${
          this.definition.hint ? " (" + this.definition.hint + ")" : ""
        }`;
      }
      this.addMessage = addMessage;
      methodDebug(`addMessage`, this.addMessage);
      return search && search.trim();
    },

    validateNewItem(newItem) {
      const methodDebug = debug.extend("validateNewItem"); //eslint-disable-line
      this.errorState = false;
      this.errorMessages = "";
      methodDebug("In validateNewItem", newItem, this.attributes?.other);
      const failures = performValidations(
        newItem,
        this.attributes?.other?.rules || []
      );
      methodDebug(`failures`, failures);
      if (failures.length) {
        this.errorState = true;
        this.errorMessages = failures.join(",");
        return false;
      }
      return true;
    },
    updateFromComboValues(comboBoxEntries) {
      const component = this;
      const methodDebug = debug.extend("updateFromComboValues"); //eslint-disable-line
      // Get the new element
      if (component.isMultiple && Array.isArray(comboBoxEntries)) {
        const existingItems = comboBoxEntries.filter(
          (item) => typeof item != "string"
        );
        const updatedItems = existingItems.map(({ id }) => id);
        const originalValues = component.fieldValue || [];
        if (!isEqual(updatedItems, originalValues)) {
          component.fieldValue = updatedItems;
          component.emitUpdate();
        }
        let actualModels = getNewItems(comboBoxEntries, (item) => {
          return component.validateNewItem(item);
        });
        methodDebug(
          `models before and after cleanup`,
          comboBoxEntries,
          actualModels
        );
        actualModels.forEach((item) => {
          if (typeof item == "string") {
            component.addSeedData(item);
          }
        });
        this.$set(component, "model", actualModels);
      } else {
        methodDebug(
          `comboBoxEntries`,
          comboBoxEntries,
          `fieldValue`,
          this.fieldValue
        );
        if (isPlainObject(comboBoxEntries)) {
          this.fieldValue = comboBoxEntries?.id;
          this.emitUpdate();
        }
      }
    },
    addSeedData(newItem) {
      const methodDebug = debug.extend("addSeedData"); //eslint-disable-line
      if (this.validateNewItem(newItem)) {
        this.$emit("addSeedData", newItem);
      }
    },
    /* 
    https://github.com/vuetifyjs/vuetify/issues/9270
    https://codepen.io/overclocked/pen/YzZvvoj
    */
    adder() {
      const methodDebug = debug.extend("adder"); //eslint-disable-line
      let searchText = (this.search || "").trim();
      let itemNotFound = !this.$refs.itemsCombobox.filteredItems.length;
      if (itemNotFound && searchText.length) {
        this.$refs.itemsCombobox.$el.querySelector(
          `[aut-combo-field="${this.definition.name}"]`
        ).value = "";
        methodDebug(
          this.$refs.itemsCombobox.$el.querySelector(
            `[aut-combo-field="${this.definition.name}"]`
          )
        );
        methodDebug(`adder attempting to add`, searchText);
        this.addSeedData(searchText);
      }
    },
    updateModel() {
      const methodDebug = debug.extend("updateModel"); //eslint-disable-line
      methodDebug(`updateModel got called`);
      const component = this;
      this.search = null;
      let value = this.fieldValue;

      if (!value) {
        return;
      }
      if (this.isMultiple) {
        if (typeof value == "string") {
          value = [value];
        }
        if (!value.length) {
          return;
        }
        // Existing values
        const models = [];
        const existingIDs = (this.items || []).map((item) => item.id);
        (value || []).forEach((id) => {
          if (existingIDs.includes(id)) {
            const model = (this.items || []).find((item) => {
              return item.id == id;
            });
            if (model) {
              models.push(model);
            }
          } else {
            models.push({ name: id, id: id });
          }
        });
        // Other values:
        component.model = models;
      } else {
        const model = (this.items || []).find((item) => {
          return item.id == value;
        });
        component.model = model || value;
      }
    },
  },
};
</script>
