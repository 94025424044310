import questionaire from "./samples/epilepsy_questionaire";
import two_events_questionaire from "./samples/two_events_questionaire";
import two_exclusive_events_questionaire from "./samples/two_exclusive_events_questionaire";
import neuroscreen_questionaire from "./samples/neuroscreen_questionaire";

const debug = require("debug")("atman.components.questionaire"); // eslint-disable-line

export default {
  label: "Questionaire",
  type: "questionaire",
  description: "Displays a Questionaire",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Questionaire.",
      label: "Input (Hide Answered)",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        definition: questionaire,
        name: "default",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "disable_answered_question",
      description: "Answered questions can be disabled",
      label: "Input (Disabled answered)",
      variables: {
        data: {
          disable_answered_question: null,
        },
      },
      value: {
        definition: questionaire,
        name: "disable_answered_question",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "disable",
          },
        },
      },
    },
    {
      id: "display",
      description: "Display mode",
      label: "Display",
      variables: {
        data: {
          display: {
            responses: {
              "general-1": "yes",
              "epilepsy-1": "yes",
              "epilepsy-2": "yes",
              "epilepsy-3": "yes",
              "epilepsy-4": "yes",
              "epilepsy-5": "yes",
            },
            outcome: {
              id: "general_seizure",
              label: "Probably Generalised Seizure",
            },
          },
        },
      },
      value: {
        definition: questionaire,
        name: "display",
        label: "Questionaire",
        type: "questionaire",
        mode: "display",
      },
    },
    {
      id: "two_events",
      description:
        "Example of multiple events being triggered after a question is answered",
      label: "Two events Example",
      variables: {
        data: {
          two_events: null,
        },
      },
      value: {
        definition: two_events_questionaire,
        name: "two_events",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
      },
    },
    {
      id: "exclusive_events",
      description:
        "If an event is configured as exclusive, subsequent ones will not be triggered even if their conditions are satisfied",
      label: "Exclusive events Example",
      variables: {
        data: {
          exclusive_events: null,
        },
      },
      value: {
        definition: two_exclusive_events_questionaire,
        name: "exclusive_events",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
      },
    },
    {
      id: "neuroscreen",
      description: "Neuroscreen questionaire",
      label: "Neuroscreen",
      variables: {
        data: {
          neuroscreen: null,
        },
      },
      value: {
        definition: neuroscreen_questionaire,
        name: "neuroscreen",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "answered_questions",
      type: "select",
      hint: "What to do with answered questions",
      value: "",
      options: ["hide"],
    },
  ],
};
