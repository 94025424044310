const PUBLISH_EVENT = "publish_event";
const SHOW_HISTORY = "_show_history";
const SHOW_DIALOG = "SHOW_DIALOG";
const SHOW_CONFIRMATION = "SHOW_CONFIRMATION";
const CONFIRMATION_CANCEL = "CONFIRMATION_CANCEL";
const CONFIRMATION_CONFIRM = "CONFIRMATION_CONFIRM";
const CLOSE_DIALOG = "_close_dialog";
const LOGIN_EVENT = "_login";
const ADD_MESSAGE = "add_message";
const SHOW_TUTORIAL = "show_tutorial";
const EMAIL_PAGE = "_email_page";
const CONTEXT_UPDATE = "CONTEXT_UPDATE";
const CHOSEN_VALUE = "_chosen_value";
const REFRESH_SKIN = "_refresh_skin";
const DISPLAY_PAGE_EVENT = "_display_page";
const DISPLAY_DIALOG_EVENT = "_display_dialog";
const EMAIL_PAGE_EVENT = "_email_page";
const SET_SKIN = "set_skin";
const SET_PREFERENCES = "set_preferences";
const PAGINATION_START = 0;

const NO_PAGE = "This page does not exist yet. Would you like to create it?";
const NO_PAGE_REQUEST_ADMIN =
  "This page does not exist yet. Please request an administrator to create it.";

const LAYOUTS = {
  CLASSIC: "classic",
  TWO_PANEL: "two_panel",
  THREE_PANEL: "three_panel",
};

const MESSAGES = {
  NO_PAGE,
  NO_PAGE_REQUEST_ADMIN,
  MANDATORY: "This field is required",
  INVALID_LINK: "Is not a valid link",
  EMAIL: "Invalid Email",
  INVALID_REGEX: `Invalid input`,
  USERNAME: "Invalid username. Allowed patterns: alphanumeric (lower case), _",
  INVALID_ENTITY_PATH: "Invalid text. Allowed patterns: lowercase, _, /",
  INVALID_ENTITY:
    "Invalid text. Allowed patterns: lowercase, _ (can be used twice between words).",
  INVALID_PHONE_NUMBER: `Invalid Phone number`,
};

const STORE_CONSTS = {
  ID: "ID",
  SCHEMA: "SCHEMA",
  CONTEXT: "CONTEXT",
  DEFINITION: "DEFINITION",
  DATA: "DATA",
  ORIGINAL_DATA: "ORIGINAL_DATA",
  FIELD: "FIELD",
  DESIGN: "DESIGN",
  SELECTED: "SELECTED",
};

const PAGES = {
  CREATE_USER: "create_user",
};

const PERMISSIONS = [
  {
    id: "NA",
    name: "No Access",
  },
  {
    id: "R",
    name: "Read",
  },
  {
    id: "W",
    name: "Write",
  },
  {
    id: "E",
    name: "Execute",
  },
];

export {
  MESSAGES,
  PAGINATION_START,
  PERMISSIONS,
  LAYOUTS,
  DISPLAY_DIALOG_EVENT,
  DISPLAY_PAGE_EVENT,
  EMAIL_PAGE_EVENT,
  ADD_MESSAGE,
  SHOW_TUTORIAL,
  STORE_CONSTS,
  PUBLISH_EVENT,
  SHOW_HISTORY,
  SHOW_DIALOG,
  CLOSE_DIALOG,
  LOGIN_EVENT,
  CONTEXT_UPDATE,
  CHOSEN_VALUE,
  REFRESH_SKIN,
  SET_SKIN,
  SET_PREFERENCES,
  SHOW_CONFIRMATION,
  CONFIRMATION_CANCEL,
  CONFIRMATION_CONFIRM,
  EMAIL_PAGE,
  PAGES,
};
