<template>
  <v-card
    v-bind="displayAttributes"
    :class="displayClasses"
    :width="effectiveWidth"
    :min-height="150"
    ripple
    v-if="fieldValue"
  >
    <v-img v-if="fieldValue.img" min-height="100" :src="fieldValue.img" fill>
    </v-img>
    <v-card-title v-if="fieldValue.title">
      <v-container>
        <v-row justify="center">
          <v-icon :class="iconClasses" v-if="fieldValue.icon" large>
            {{ fieldValue.icon }}
          </v-icon>
        </v-row>
        <v-row justify="center" :class="applicationMenuTitleClasses">
          {{ fieldValue.title }}
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text v-if="fieldValue.description">
      {{ fieldValue.description }}
    </v-card-text>
  </v-card>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.components.application_menu");
debug("application_menu");
export default {
  name: "ApplicationMenuField",
  mixins: [fieldMixin],
  computed: {
    applicationMenuTitleClasses() {
      let result = "behavior_break_word text-center";
      result += this.fieldValue?.disabled ? " grey--text" : " white--text";
      return result;
    },
    iconClasses() {
      let result = "mr-2 ";
      result += this.fieldValue?.disabled ? " grey--text" : " white--text";
      return result;
    },
    effectiveWidth() {
      if (this.isSmallScreen) {
        return this.displayAttributes?.["mobile-screen-width"] || "20vw";
      }
      return this.displayAttributes?.["large-screen-width"] || "20vw";
    },
  },
  data() {
    return {
      definition,
    };
  },
  mounted() {
    debug(`In Application Menu Field`, this.fieldValue);
  },
};
</script>
