<template>
  <div>
    <div class="pb-4 pt-2">
      <label>
        This field uses the
        <a
          target="_blank"
          href="https://github.com/CacheControl/json-rules-engine"
          >json-rules-engine</a
        >
        library internally.
      </label>
      <!-- <v-switch
        label="Show Tips"
        dense
        v-model="showTips"
      ></v-switch> -->
    </div>
    <v-tabs v-model="tabModel" vertical>
      <v-tab aut-tab-start>Start</v-tab>
      <v-tab aut-tab-categories>Categories</v-tab>
      <v-tab aut-tab-events>Events</v-tab>
      <v-tab aut-tab-summary>Summary</v-tab>
      <v-tab-item :key="'start' + refreshKey">
        <TriggerEventDesigner
          :open_on_load="true"
          :fixed_type="true"
          :event="questionnaireDefinition.start"
          :multiple="false"
        />
      </v-tab-item>
      <v-tab-item :key="'category' + refreshKey">
        <v-tabs v-model="categoryPanel" aut-category-panel>
          <v-tab v-for="category in categoriesList" :key="category.id + 'tab'">
            {{ category.label }}
          </v-tab>
          <v-tab-item
            v-for="category in categoriesList"
            :key="category.id + 'item'"
          >
            <v-row dense class="pt-4">
              <v-col cols="12">
                <v-text-field
                  dense
                  label="Label"
                  hint="Label of category"
                  persistent-hint
                  clearable
                  v-model="category.label"
                  @blur="updateCategory(category)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="10">
                    <v-text-field
                      dense
                      label="Color"
                      hint="Background color for category title"
                      persistent-hint
                      clearable
                      v-model="category.display.attributes.color"
                      @blur="updateCategory(category)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-color-picker
                      class="mt-n2"
                      dense
                      disabled
                      dot-size="1"
                      hide-canvas
                      hide-inputs
                      hide-mode-switch
                      swatches-max-height="100"
                      label="Color"
                      hint="Color of category"
                      persistent-hint
                      clearable
                      v-model="category.display.attributes.color"
                      @blur="updateCategory(category)"
                    ></v-color-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <EventsDesigner :events="category.events" />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item :key="'events' + refreshKey">
        <EventsDesigner :events="questionnaireDefinition.events" />
      </v-tab-item>
      <v-tab-item :key="'summary' + refreshKey">
        <v-text-field
          dense
          label="Summary Title"
          clearable
          v-model="summaryTitle"
        ></v-text-field>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { updateFeatureFlag } from "@/feature_flags";
import { defaultsDeep, forOwn } from "lodash";
import EventsDesigner from "./events/EventsDesigner.vue";
import TriggerEventDesigner from "./events/TriggerEventDesigner.vue";

const debug = require("debug")("atman.components.questionnaire_designer"); // eslint-disable-line

export default {
  name: "QuestionaireDesigner",
  components: {
    EventsDesigner,
    TriggerEventDesigner,
  },
  props: {
    definition: {
      type: Object,
    },
    controls: {
      type: Array,
      default: () => [],
    },
    isList: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      refreshKey: 1,
      categoryPanel: 0,
      tabModel: 0,
      questionnaireDefinition: defaultsDeep(this.definition.definition, {
        start: {
          params: {
            id: "",
          },
        },
        categories: {},
        events: {},
        summary: {
          title: "",
        },
      }),
    };
  },
  computed: {
    showTips: {
      get() {
        return this.isFeatureEnabled("questionaire_designer_tips_enabled");
      },
      set(val) {
        updateFeatureFlag("questionaire_designer_tips_enabled", val);
        debug(
          "updating value, ",
          this.isFeatureEnabled("questionaire_designer_tips_enabled")
        );
        this.refreshKey++;
      },
    },
    categoriesList: {
      get() {
        let result = [];
        forOwn(this.questionnaireDefinition.categories, (val, key) => {
          result.push(
            defaultsDeep({ id: key }, val, {
              events: {},
              display: {
                attributes: {
                  color: "",
                },
                classes: [],
              },
            })
          );
        });
        return result;
      },
      set(val) {
        val.forEach((item) => {
          this.questionnaireDefinition.categories[item.id] = item;
        });
      },
    },

    summaryTitle: {
      get() {
        return this.questionnaireDefinition?.summary?.title || "";
      },
      set(val) {
        this.questionnaireDefinition.summary.title = val;
      },
    },
  },
  methods: {
    updateCategory(category) {
      debug(`updateCategory Invoked`, category);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-color-picker__sliders {
    display: none !important;
  }
}
</style>
