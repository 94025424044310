<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field v-model="dataURL" label="url" @blur="getVariables" />
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tabModel" vertical @change="tabModel = $event">
        <v-tab aut-tab-context>
          <v-tab-title class="d-flex">
            <div>Context</div>
            <ToolTip :content="tooltips.route" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-user>
          <v-tab-title class="d-flex">
            <div>Profile</div>
            <ToolTip :content="tooltips.profile" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-user>
          <v-tab-title class="d-flex">
            <div>Access Control</div>
            <ToolTip :content="tooltips.access_control" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-runtime>
          <v-tab-title class="d-flex">
            <div>Runtime</div>
            <ToolTip :content="tooltips.runtime" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-data v-if="showData">
          <v-tab-title class="d-flex">
            <div>Data</div>
            <ToolTip :content="tooltips.data" /> </v-tab-title
        ></v-tab>
        <v-tab aut-tab-route>
          <v-tab-title class="d-flex">
            <div>Route</div>
            <ToolTip :content="tooltips.route" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-features>
          <v-tab-title class="d-flex">
            <div>Features</div>
            <ToolTip :content="tooltips.features" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-settings>
          <v-tab-title class="d-flex">
            <div>Settings</div>
            <ToolTip :content="tooltips.settings" />
          </v-tab-title>
        </v-tab>
        <v-tab aut-tab-display>
          <v-tab-title class="d-flex">
            <div>Display</div>
            <ToolTip :content="tooltips.display" />
          </v-tab-title>
        </v-tab>
        <v-tab-item aut-tab-item-context>
          <VariablesList :variables="contextVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-user>
          <VariablesList :variables="userVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-user>
          <VariablesList :variables="accessControlVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-runtime>
          <VariablesList :variables="runtimeVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-data v-if="showData">
          <VariablesList :variables="dataVariables" :list="isList" />
        </v-tab-item>
        <v-tab-item aut-tab-item-route>
          <VariablesList :variables="routeVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-features>
          <VariablesList :variables="featureVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-settings>
          <VariablesList :variables="settingsVariables" />
        </v-tab-item>
        <v-tab-item aut-tab-item-display>
          <VariablesList :variables="displayVariables" />
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import { isPlainObject } from "lodash";
import { createContext, getVariablesFromObj } from "@/util";
import VariablesList from "./VariablesList.vue";
import { mapState } from "vuex";
import { fieldMixin } from "@/components/mixin.js";
import { featureFlagsObject } from "@/feature_flags";

const debug = require("debug")("atman.components.variables_tutorial"); // eslint-disable-line

const tooltips = {
  features: `<p>Feature variables represent feature flags</p>`,
  settings: `<p>Settings variables represent all the domain level settings`,
  display: `<p>Display variables represent information about the theme, form factor etc. </p>`,
  context: `<p>
                  Context variables represent the context the page is in. They
                  are created by breaking down the URL
                </p>`,
  profile: `<p>Profile variables represent the logged in user's data</p>`,
  access_control: `<p>
                Access control variables represent permissions the user has
              </p>`,
  runtime: `<p>
                Runtime variables represent runtime information that you
                may want to include in the submitted request
              </p>`,
  route: `<div>
                Route variables will typically be the same as context variables. 
              </div>
              <div>
                For a pinned page,
                <ul>
                  <li>
                    context variables will refer to its own url (at the time of
                    pinning)
                  </li>
                  <li>
                    route variables will refer to the page it is currently
                    pinned to
                  </li>
                </ul>
              </div>`,
  data: `<div>
                <ul>
                  <li>
                    This tab will only contain information if a 'data' api has
                    been specified
                  </li>
                  <li>The values shown here are representative. Only the first row of results have been shown</li>
                  <li>
                    <div>
                    <div>The index notation \`[0]\` can be replaced by \`[i]\` to get the
                    data at that row </div>
                    <div><i>i.e. to get the data in the 1st row,
                    you can use \`_data->[0]\` and to get the data in the 2nd row,
                     you can use \`_data->[1]\` and so on</i>
                    </div>
                    </div>
                  </li>
                  <li>
                    Within a row, \`_data->[i]->node\` can be replaced by \`_row->node\`
                  </li>
                </ul>
              </div>`,
};

export default {
  name: "VariablesTutorial",
  mixins: [fieldMixin],
  components: {
    VariablesList,
  },

  props: {
    url: {
      type: String,
    },
    store_key: {
      type: String,
    },
  },
  data() {
    return {
      dataURL: this.url || this.$route.fullPath,
      fieldContextKey: null,
      jsonUpdateKey: 1,
      dataVariables: [],
      routeVariables: [],
      contextVariables: [],
      runtimeVariables: [],
      accessControlVariables: [],
      userVariables: [],
      tabModel: null,
      isList: false,
      showData: true,
    };
  },
  created() {
    this.tooltips = tooltips;
  },
  mounted() {
    this.getVariables();
  },
  computed: {
    ...mapState(["user"]),
    settingsVariables() {
      const variables =
        this?.$store?.getters?.[`${this.store_key}/settingsVariables`] || {};
      let result = getVariablesFromObj({
        input: variables,
        prefix: "_settings->",
        category: "settings",
      });
      debug(`settingsVariables`, result);
      return result || [];
    },
    displayVariables() {
      const variables =
        this?.$store?.getters?.[`${this.store_key}/displayVariables`] || {};
      let result = getVariablesFromObj({
        input: variables,
        prefix: "_display->",
        category: "display",
      });
      debug(`displayVariables`, result);
      return result || [];
    },
    featureVariables() {
      let result = getVariablesFromObj({
        input: featureFlagsObject || {},
        prefix: "_features->",
        category: "feature",
      });
      debug(`featureVariables`, result);
      return result || [];
    },
  },
  methods: {
    getRouteVariables() {
      const variables =
        this?.$store?.getters?.[`${this.store_key}/routeVariables`];
      if (!variables) {
        return [];
      }
      let result = getVariablesFromObj({
        input: variables,
        prefix: "_route->",
        category: "route",
      });
      debug(`routeVariables`, result);
      return result || [];
    },
    getAccessControlVariables() {
      const result =
        this?.$store?.getters?.[`${this.store_key}/accessControlVariables`];
      return result || [];
    },
    getContextVariables() {
      const contextVariables =
        this?.$store?.getters?.[`${this.store_key}/contextVariables`];
      if (contextVariables && contextVariables.length) {
        return contextVariables;
      }

      let url = this.dataURL;
      if (url.indexOf("http") != -1) {
        const urlObject = new URL(this.dataURL);
        url = urlObject.pathname;
        if (urlObject.search) {
          url += urlObject.search;
        }
      }
      const context = createContext(url);
      return getVariablesFromObj({
        input: context,
        prefix: "_context->",
        category: "context",
      });
    },
    getUserVariables() {
      const userVariables =
        this?.$store?.getters?.[`${this.store_key}/userVariables`];
      return userVariables || [];
    },
    getRuntimeVariables() {
      const runtimeVariables =
        this?.$store?.getters?.[`${this.store_key}/runtimeVariables`];
      return runtimeVariables || [];
    },
    async getDataVariables() {
      const variables = await this?.$store?.dispatch(
        `${this.store_key}/getData`,
        {
          force: true,
        }
      );
      if (isPlainObject(variables)) {
        return getVariablesFromObj({
          input: variables,
          prefix: "_data->",
          category: "data",
        });
      } else if (Array.isArray(variables) && variables.length) {
        let output = [];
        const variablesInRow = getVariablesFromObj({
          input: variables[0],
          prefix: `_data->[0]->`,
          category: "data",
        });
        output = [...output, ...variablesInRow];

        return output;
      }
      return [];
    },
    async getVariables() {
      this.contextVariables = this.getContextVariables();
      this.userVariables = this.getUserVariables();
      this.runtimeVariables = this.getRuntimeVariables();
      this.dataVariables = await this.getDataVariables();
      this.routeVariables = this.getRouteVariables();
      this.accessControlVariables = this.getAccessControlVariables();
    },
  },
};
</script>
