<template>
  <v-row class="pa-0">
    <v-col :cols="12">
      <div>
        <v-autocomplete
          :aut-select-field="definition.name"
          v-model="fieldValue"
          :items="items"
          item-text="name"
          item-value="id"
          :label="label"
          @blur="emitFieldValue"
          @change="emitFieldValue"
          :deletable-chips="definition.multiple"
          :small-chips="definition.multiple"
          :rules="rules"
          :multiple="definition.multiple"
          :chips="definition.multiple"
          v-bind="attributes"
          v-bind:class="classes"
          :hint="definition.hint"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ item.name || item }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            v-slot:selection="{ item, index }"
            v-if="definition.multiple && attributes.atman_truncate"
          >
            <v-chip small v-if="index === 0">
              <span v-if="fieldValue">
                {{ item.name }}
                {{
                  fieldValue.length > 1 ? " + " + (fieldValue.length - 1) : ""
                }}
              </span>
            </v-chip>
          </template>
          <template v-slot:append v-if="appendAttributes.icon">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  @click.stop="appendIconAction"
                >
                  {{ appendAttributes.icon }}
                </v-icon>
              </template>
              {{ appendAttributes.hint }}
            </v-tooltip>
          </template>
          <template v-slot:append v-else-if="showDetail">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  @click="$emit('show_detailed_view')"
                  color="primary"
                  aut-show-detail
                >
                  mdi-file-table-box-outline
                </v-icon>
              </template>
              Detailed View
            </v-tooltip>
          </template>
        </v-autocomplete>
      </div>
      <div :id="attachID" class="behavior_menu_container"></div>
    </v-col>
  </v-row>
</template>
<script>
import dropdownMixin from "./mixin.js";
export default {
  name: "DropdownSelect",
  mixins: [dropdownMixin],
  computed: {
    attachID() {
      return `dropdown-${this.definition.name}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_menu_container {
  position: relative;
}
</style>
