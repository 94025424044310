import { isPlainObject, sortBy } from "lodash";
import { clone } from "@/util";
import fieldProperties from "./field_properties.js";
import InputText from "./InputText";
import Availability from "./Availability";
import AppointmentScheduler from "./AppointmentScheduler";
import PasswordField from "./PasswordField";
import PageEditor from "./PageEditor";
import TextArea from "./TextArea";
import EmbedWidget from "./EmbedWidget";
import LabelField from "./LabelField";
import LookupField from "./LookupField";
import Dropdown from "./Dropdown";
import Action from "./Action";
import RichTextEditor from "./RichTextEditor";
import CostField from "./CostField";
import CountDownClock from "./CountDownClock";
import FeatureField from "./FeatureField";
import JSONEditor from "./JSONEditor";
import CodeEditor from "./CodeEditor";
import ChecklistField from "./ChecklistField";
import TimeDuration from "./TimeDuration";
import CheckboxField from "./CheckboxField";
import DatePicker from "./DatePicker";
import DateRange from "./DateRange";
import FilteredList from "./FilteredList";
import ApplicationMenuTree from "./ApplicationMenuTree";
import TableField from "./TableField";
import ToggleSwitch from "./ToggleSwitch";
import RadioButton from "./RadioButton";
import KeyValuePair from "./KeyValuePair";
import Slider from "./Slider";
import Hyperlink from "./Hyperlink";
import FieldTutorial from "./FieldTutorial";
import ProcessorTutorial from "./ProcessorTutorial";
import PermissionsManager from "./PermissionsManager";
import RuleTutorial from "./RuleTutorial";
import VariablesTutorial from "./VariablesTutorial";
import Actions from "./Actions";
import ObjectField from "./ObjectField";
import WorkflowEditor from "./WorkflowEditor";
import ArrayField from "./ArrayField";
import Chart from "./Chart";
import FileField from "./FileField";
import TreeView from "./TreeView";
import TimePicker from "./TimePicker";
import ImageField from "./ImageField";
import PageManager from "./PageManager";
import Divider from "./Divider";
import Calendar from "./Calendar";
import EntityField from "./EntityField";
import IconField from "./IconField";
import DueDate from "./DueDate";
import Currency from "./Currency";
import QuarterPicker from "./QuarterPicker";
import ApplicationMenuField from "./ApplicationMenuField";
import DateTimePicker from "./DateTime";
import Questionaire from "./Questionaire";
import MonthPicker from "./MonthPicker";
import EventScheduler from "./EventScheduler";
import { isFeatureEnabled } from "@/feature_flags";
import Captcha from "./Captcha";
import UserBadge from "./UserBadge";
import VideoField from "./VideoField";
import SpacerField from "./SpacerField";
import ImageListField from "./ImageListField";
import PersonName from "./PersonName";
import LandlineNumber from "./LandlineNumber";
import AddressField from "./AddressField";
import { PERMISSIONS } from "@/constants";
import Rating from "./Rating";
import Cart from "./Cart";
import UserName from "./UserName";
import Book from "./Book";
import Events from "./Events";
import BookCatalogue from "./BookCatalogue";

const featureFlaggedFields = [];
if (isFeatureEnabled("event_scheduler")) {
  featureFlaggedFields.push(EventScheduler);
}

const components = sortBy(
  [
    InputText,
    UserBadge,
    IconField,
    EntityField,
    AppointmentScheduler,
    Availability,
    PageEditor,
    TextArea,
    ChecklistField,
    LabelField,
    LookupField,
    PasswordField,
    Dropdown,
    FieldTutorial,
    CountDownClock,
    FeatureField,
    Action,
    RichTextEditor,
    CostField,
    CheckboxField,
    JSONEditor,
    TableField,
    CodeEditor,
    DatePicker,
    ToggleSwitch,
    RadioButton,
    KeyValuePair,
    ApplicationMenuTree,
    Slider,
    Hyperlink,
    Actions,
    ObjectField,
    WorkflowEditor,
    ProcessorTutorial,
    RuleTutorial,
    ArrayField,
    Chart,
    FileField,
    TreeView,
    ImageField,
    PageManager,
    Divider,
    TimePicker,
    FilteredList,
    DateRange,
    Calendar,
    DueDate,
    Currency,
    QuarterPicker,
    ApplicationMenuField,
    DateTimePicker,
    MonthPicker,
    VariablesTutorial,
    Captcha,
    VideoField,
    SpacerField,
    PermissionsManager,
    ImageListField,
    PersonName,
    LandlineNumber,
    AddressField,
    TimeDuration,
    Rating,
    Questionaire,
    Cart,
    UserName,
    Book,
    Events,
    EmbedWidget,
    BookCatalogue,
    ...featureFlaggedFields,
  ],
  ["definition.label"]
);

const types = {};
const controls = {};
const designers = {};

components.forEach((component) => {
  const properties = [...(component.definition.properties || [])];
  component.definition.properties = [];
  properties.forEach((property) => {
    if (typeof property == "object") {
      component.definition.properties.push(property);
    } else if (typeof property == "string") {
      const propertyValue = fieldProperties.find(({ key }) => {
        return key == property;
      });
      component.definition.properties.push(propertyValue);
    }
  });

  types[component.definition.type] = component.definition;
  controls[component.definition.type] = component.control;
  designers[component.definition.type] = component.designer;
});

const fieldsWithSearch = components.filter(({ definition }) => {
  return !!definition.searchField;
});

const getPermissionTypes = (fieldDefinition) => {
  let result = clone(PERMISSIONS.filter((type) => type.id != "E"));
  let permissionTypes = clone(
    types[fieldDefinition.type].permission_types || []
  );
  if (permissionTypes.length) {
    result = permissionTypes;
  }
  return result.map((item) => {
    if (isPlainObject(item)) {
      return item;
    }
    return {
      id: item,
      name: PERMISSIONS.find((thing) => thing.id == item).name,
    };
  });
};

export { types, designers, controls, fieldsWithSearch, getPermissionTypes };
