<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-add-menu-dialog>
      <DialogTitle @close="$emit('close')" :title="buttonLabel" />
      <v-card-text>
        <Hyperlink
          v-model="linkField"
          :disable_url="true"
          @change="changeLinkDetails"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="$emit('close')"
          aut-action-add-menu-cancel
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          aut-add-menu
          @click="updateMenu"
          class="ml-2"
          >{{ buttonLabel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { mapActions, mapMutations } from "vuex";
import { updateMenu, getMenuDetails } from "@/components/editor/util.js";
const debug = require("debug")("atman.components.add_menu_dialog");
debug(`add_menu_dialog`);

export default {
  name: "AddMenuDialog",
  mixins: [dialogMixin],
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    favorite: {
      type: Boolean,
    },
  },
  computed: {
    isValid() {
      return !!this.label;
    },
    buttonLabel() {
      let result;
      if (this.favorite) {
        result = "Add Favourite";
      } else {
        const prefix = this.isPresent ? "Update" : "Add";
        result = `${prefix} Menu`;
      }
      return result;
    },
    linkField: {
      get() {
        let attributes = {};
        if (this.favorite) {
          attributes = {
            hide_icon: true,
            hide_target: true,
          };
        }
        return {
          display: {
            attributes,
          },
          type: "link",
          mode: "input",
          value: {
            href: this.link,
            title: this.label,
            icon: this.icon,
          },
        };
      },
    },
  },
  components: {
    Hyperlink: () => import("@/components/fields/Hyperlink/Hyperlink.vue"),
  },
  data() {
    return {
      link: this.url,
      icon: this.favorite ? "mdi-star" : "",
      display: true,
      applicationID: "",
      application: null,
      isPresent: false,
      label: this.title || "",
    };
  },
  async mounted() {
    debug(`In mounted of AddMenuDialog`, this.link);
    if (!this.link) {
      this.link = this.$route.fullPath;
    }
    const menuDetails = await getMenuDetails(this.link);
    this.application = menuDetails?.application;
    this.isPresent = !!menuDetails?.isPresent;
    this.label = menuDetails?.label || this.label;
    this.icon = menuDetails?.icon || this.icon;
  },
  methods: {
    ...mapMutations("preferences", ["addFavourite"]),
    changeLinkDetails(value) {
      this.icon = value.icon;
      this.label = value.title;
    },
    ...mapActions(["fetchMenu"]),
    async updateMenu() {
      const component = this;
      if (this.favorite) {
        const fav = {
          href: component.link,
          title: component.label,
          icon: component.icon,
        };
        this.addFavourite(fav);
        this.displaySuccessMessage("Favourite updated");
        this.$emit("close");
        return;
      }
      await updateMenu({
        application: component.application,
        url: component.link,
        isPresent: component.isPresent,
        label: component.label,
        icon: component.icon,
      });
      this.displaySuccessMessage("Menu updated");
      this.fetchMenu({ force: true });
      this.$emit("close");
    },
  },
};
</script>
