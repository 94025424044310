const debug = require("debug")("atman.components.table");//eslint-disable-line
export default {
  label: "Table",
  type: "table",
  description: "Displays a Table field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Table",
      label: "Default",
      value: {
        name: "some_name",
        label: "Title",
        type: "table",
        definition: {
          apis: {
            data: {
              url: "/applications/ticket_management/tickets",
              type: "get",
            },
          },
          definition: {
            title: "Ticketing System",
            bulk_operation: true,
            fields: [
              {
                label: "Summary",
                display: {
                  width: "15",
                },
                bulk_operation: true,
                name: "title",
                mode: "display",
                type: "text",
              },
              {
                label: "Assignee",
                display: {
                  width: "15",
                },
                bulk_operation: true,
                name: "assignee",
                mode: "input",
                type: "select",
                options: {
                  apis: {
                    data: {
                      url: "users",
                      type: "get",
                    },
                  },
                },
                apis: {
                  submit: {
                    url: "/applications/ticket_management/tickets/{_row->id}",
                    type: "post",
                    params: {
                      action: "update_fields",
                    },
                  },
                },
              },
              {
                label: "Type",
                display: {
                  width: "15",
                },
                bulk_operation: true,
                name: "type",
                mode: "display",
                type: "select",
                options: {
                  apis: {
                    data: {
                      url: "/applications/ticket_management?lookup=types",
                      type: "get",
                    },
                  },
                },
              },
              {
                label: "Created",
                display: {
                  width: "15",
                },
                name: "_created_date",
                mode: "display",
                type: "date",
              },
              {
                label: "Priority",
                display: {
                  width: "15",
                },
                bulk_operation: true,
                name: "priority",
                type: "select",
                mode: "display",
                options: {
                  apis: {
                    data: {
                      url: "/applications/ticket_management?lookup=priorities",
                      type: "get",
                    },
                  },
                },
              },
              {
                label: "Actions",
                display: {
                  width: "15",
                },
                name: "actions",
                type: "actions",
                value: [
                  {
                    name: "view",
                    icon: "mdi-eye",
                    hint: "View",
                    type: "action",
                    value: {
                      type: "event",
                      name: "display_ticket_details",
                      params: {
                        id: "{_row->id}",
                      },
                    },
                  },
                  {
                    name: "edit",
                    hint: "Edit",
                    icon: "mdi-pencil",
                    type: "action",
                    value: {
                      type: "dialog",
                      url: "{_row->_url}?page=edit",
                    },
                  },
                  {
                    name: "delete",
                    hint: "Delete",
                    icon: "mdi-delete",
                    type: "action",
                    confirmation:
                      "This is an irreversible action. Are you sure?",
                    value: {
                      type: "post",
                      params: {
                        action: "mark_for_deletion",
                      },
                      url: "{_row->_url}",
                      success: {
                        message: "Deletion successful",
                      },
                    },
                    display: {
                      attributes: {
                        color: "error",
                      },
                    },
                  },
                ],
              },
            ],
            actions: [
              {
                name: "add_ticket",
                label: "Add ticket",
                type: "action",
                value: {
                  type: "navigation",
                  url: "{_context->path}?page=create",
                },
              },
              {
                name: "delete_tickets",
                bulk_operation: true,
                label: "Delete tickets",
                type: "action",
                confirmation: "This is an irreversible action. Are you sure?",
                value: {
                  type: "post",
                  url: "{_context->path}",
                  params: {
                    action: "DELETE",
                  },
                  success: {
                    message: "Tickets deleted successfully",
                  },
                },
              },
            ],
          },
        },
      },
    },
  ],
};
