import application_home_form from "./forms/application_home_form";

const menuCards = {
  title: "",
  type: "list",
  display: {
    attributes: {
      "disable-filtering": true,
    },
  },
  definition: {
    mode: "cards",
    fields: [
      {
        name: "menu",
        type: "application_menu",
        click_action: {
          type: "navigation",
          url: "{_data->[i].href}",
        },
      },
    ],
    actions: [],
  },
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
      field: "menu.child",
    },
  },
};

const forms = [
  {
    id: "application_home_form",
    label: "Application Home Form",
    value: application_home_form,
  },
];

const pages = [
  {
    id: "home",
    label: "Application Home Page",
    value: {
      type: "page",
      id: "new_application",
      content: [application_home_form, menuCards],
    },
  },
];

export { forms, pages };
