var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',_vm._b({ref:"itemsCombobox",class:_vm.classes,attrs:{"aut-combo-field":_vm.definition.name,"search-input":_vm.search,"item-text":"name","item-value":"id","items":_vm.items,"rules":_vm.applicableRules,"error":_vm.errorState,"error-messages":_vm.errorMessages,"filter":_vm.filter,"label":_vm.label,"hide-no-data":!_vm.search,"multiple":_vm.isMultiple,"hint":_vm.definition.hint},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"blur":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.adder.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.name || item)+" ")])],1)],1)]}},{key:"no-data",fn:function(){return [(_vm.isValid(_vm.search) && _vm.addMessage)?_c('v-list-item',[_c('span',{staticClass:"aut-dropdown-search-message subheading"},[_vm._v(_vm._s(_vm.addMessage))])]):_vm._e()]},proxy:true},(_vm.definition.multiple && _vm.attributes.atman_truncate)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[(_vm.fieldValue)?_c('span',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.fieldValue.length > 1 ? " + " + (_vm.fieldValue.length - 1) : "")+" ")]):_vm._e()]):_vm._e()]}}:null,(_vm.appendAttributes.icon)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.appendIconAction.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.appendAttributes.icon)+" ")])]}}],null,false,492014038)},[_vm._v(" "+_vm._s(_vm.appendAttributes.hint)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-combobox',_vm.attributes,false))}
var staticRenderFns = []

export { render, staticRenderFns }