const debug = require('debug')('atman.rules')  // eslint-disable-line
import {
  format,
  parseISO,
  isBefore,
  isAfter,
  subDays,
  addDays,
  isFuture,
  isToday,
} from "date-fns";

import { MESSAGES } from "@/constants";
import { isPlainObject, uniq, isBoolean } from "lodash";
const EMAIL_REGEX = /.+@.+\..+/;
const USERNAME_REGEX =
  /^[a-z0-9]+([.$\-_@]*[a-z0-9]*)+$|(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line
const USERNAME_MIN_LENGTH = 4;
const ENTITY_REGEX = /(^[a-z0-9]+[_]*[a-z0-9]+[_]*[a-z0-9]+$)/;

const isEmpty = (value) => {
  return (`${value}` || "").trim().length == 0;
};

const performValidations = (fnToInvoke, value, options = {}) => {
  const debugKey = options.debugKey;
  let methodDebug = debug.extend(`performValidations${debugKey ? '_' + debugKey : ''}`); // eslint-disable-line

  let result;
  if (typeof value == "undefined") {
    const ignore =
      typeof options.ignoreUndefined == "boolean"
        ? options.ignoreUndefined
        : true;
    result = ignore ? true : options.message || options.defaultMessage;
    methodDebug(
      `Value is undefined. rule configured to ignore? [${ignore}]. Returning [${result}]`
    );
    return result;
  }
  if (value == null) {
    const ignore =
      typeof options.ignoreNull == "boolean" ? options.ignoreNull : true;
    return ignore ? true : options.message || options.defaultMessage;
  }
  if (isPlainObject(value)) {
    return true;
  }
  if (Array.isArray(value)) {
    if (!value.length) {
      const ignore =
        typeof options.ignoreEmptyArray == "boolean"
          ? options.ignoreEmptyArray
          : true;
      return ignore ? true : options.message || options.defaultMessage;
    }
    methodDebug(`Input is an array. Recursing`);
    let messages = value
      .map((item) => {
        options.allowEmpty = false;
        return performValidations(fnToInvoke, item, options);
      })
      .filter((item) => item !== true);
    messages = uniq(messages).join(", ");
    methodDebug(`messages: [${messages}]`);
    return messages || true;
  }
  if (typeof value == "string") {
    const allowEmpty =
      typeof options.allowEmpty == "boolean" ? options.allowEmpty : true;
    if (value.length == 0 && allowEmpty) {
      debug(
        `value [${value}] is empty but allowEmpty is [${allowEmpty}]. Returning true`
      );
      return true;
    }
    return fnToInvoke(value, options);
  } else {
    return fnToInvoke(value, options);
  }
};

const rules = {
  mandatory: (ruleDefinition = {}) => {
    const debugKey = ruleDefinition.debugKey;

    let methodDebug = debug.extend(`mandatory${debugKey ? '_' + debugKey : ''}`); // eslint-disable-line
    ruleDefinition.allowEmpty = false;
    ruleDefinition.ignoreNull = false;
    ruleDefinition.ignoreEmptyArray = false;
    ruleDefinition.ignoreUndefined = false;
    ruleDefinition.defaultMessage = MESSAGES.MANDATORY;
    return function mandatory(val) {
      const outcome = performValidations(
        (val, ruleDefinition) => {
          const message = ruleDefinition?.message;
          const errorMessage = message || MESSAGES.MANDATORY;
          return (`${val}` || "").trim().length > 0 || errorMessage;
        },
        val,
        ruleDefinition
      );
      methodDebug(`Performing mandatory checks`, val, outcome);
      return outcome;
    };
  },
  min_length: (ruleDefinition = {}) => {
    return function minlength(val) {
      return performValidations(
        (val, ruleDefinition) => {
          const { min_length, message } = ruleDefinition || {};
          if (isBoolean(val)) {
            return true;
          }
          return (
            (val || "").length >= min_length ||
            message ||
            `Min ${min_length} characters`
          );
        },
        val,
        ruleDefinition
      );
    };
  },
  max_length: (ruleDefinition = {}) => {
    return function maxLength(val) {
      return performValidations(
        (val, ruleDefinition) => {
          if (isBoolean(val)) {
            return true;
          }
          const { max_length, message } = ruleDefinition || {};
          if (isEmpty(val)) {
            return true;
          }
          return (
            (val || "").length <= max_length ||
            message ||
            `Max ${max_length} characters`
          );
        },
        val,
        ruleDefinition
      );
    };
  },
  entity_name: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};
    return function entityName(value) {
      if (isEmpty(value)) {
        return true;
      }
      return ENTITY_REGEX.test(value) || message || MESSAGES.INVALID_ENTITY;
    };
  },
  entity_path: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};
    return function entityPath(value) {
      if (isEmpty(value)) {
        return true;
      }
      let effectiveValue = value.replace(/[/_]/g, "");
      return (
        ENTITY_REGEX.test(effectiveValue) ||
        message ||
        MESSAGES.INVALID_ENTITY_PATH
      );
    };
  },
  email: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};
    return function email(value) {
      if (isEmpty(value)) {
        return true;
      }
      return EMAIL_REGEX.test(value) || message || MESSAGES.EMAIL;
    };
  },
  username: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};
    return function username(value) {
      if (isEmpty(value)) {
        return true;
      }
      if (value.length < USERNAME_MIN_LENGTH) {
        return message || MESSAGES.USERNAME;
      }
      return USERNAME_REGEX.test(value) || message || MESSAGES.USERNAME;
    };
  },
  email_list: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function emailList(value) {
      if (isEmpty(value)) {
        return true;
      }
      const emailIDs = value.split(",");
      const invalidIds = emailIDs.filter((email) => {
        if ((email || "").trim().length == 0) {
          return false;
        }
        return !EMAIL_REGEX.test(email);
      });
      return invalidIds.length == 0 || message || MESSAGES.EMAIL;
    };
  },
  phone_number: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};
    return function phoneNumber(value) {
      if (isEmpty(value)) {
        return true;
      }
      // May someday need to replace with https://github.com/catamphetamine/libphonenumber-js
      return (
        /^[+]?[0-9]{10,15}$/.test(value) ||
        message ||
        MESSAGES.INVALID_PHONE_NUMBER
      );
    };
  },
  regex: (ruleDefinition = {}) => {
    const methodDebug = debug.extend("regex");
    return function regex(val) {
      return performValidations(
        (val, ruleDefinition) => {
          const { regex, message } = ruleDefinition || {};
          // TODO check if it is a valid regex
          if (typeof val != "string") {
            return true;
          }
          const outcome = new RegExp(regex, "gm").test(val);
          methodDebug(`regex`, val, regex, outcome);
          return outcome || message || MESSAGES.INVALID_REGEX;
        },
        val,
        ruleDefinition
      );
    };
  },
  has_upper_case: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function hasUpperCase(value) {
      if (isEmpty(value)) {
        return true;
      }
      return /[A-Z]/.test(value) || message || "Missing uppercase characters";
    };
  },
  has_lower_case: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function hasLowerCase(value) {
      if (isEmpty(value)) {
        return true;
      }
      return /[a-z]/.test(value) || message || "Missing lowercase characters";
    };
  },
  internal_name: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function internalName(value) {
      if (isEmpty(value)) {
        return true;
      }
      return (
        /^[a-z0-9_]*$/.test(value) ||
        message ||
        "Can only only be lowercase characters, numbers or underscores"
      );
    };
  },
  only_lower_case: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function hasOnlyLowerCase(value) {
      if (isEmpty(value)) {
        return true;
      }
      return (
        /^[a-z]*$/.test(value) ||
        message ||
        "Should only be lowercase characters"
      );
    };
  },
  has_numbers: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function hasNumbers(value) {
      if (isEmpty(value)) {
        return true;
      }
      return /[0-9]/.test(value) || message || "Missing numbers";
    };
  },
  is_link: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function isLink(value) {
      if (isEmpty(value)) {
        return true;
      }
      let tweakedLink = value.toLowerCase().trim();

      // internal links will contain "/" and external links will contain "."
      if (tweakedLink.indexOf(".") == -1 && tweakedLink.indexOf("/") == -1) {
        debug(`neither an internal or external link`);
        return message || MESSAGES.INVALID_LINK;
      }
      // eslint-disable-next-line
      return /[\/A-Za-z0-9]{2,}.*[A-Za-z0-9]{2,}$/.test(tweakedLink, "gm") || message || MESSAGES.INVALID_LINK;
    };
  },
  min_value: (ruleDefinition = {}) => {
    let { min, message } = ruleDefinition;
    min = min * 1;
    return function minValue(value) {
      if (isEmpty(value)) {
        return true;
      }
      value = value * 1;
      if (isNaN(value)) {
        return false;
      }
      if (isNaN(min)) {
        console.error(
          `Invalid rule configuration. "min": [${min}] is not a number`
        );
        return false;
      }
      return (
        value >= min ||
        message ||
        `Please enter a number greater than or equal to ${min}`
      );
    };
  },
  max_value: (ruleDefinition = {}) => {
    let { max, message } = ruleDefinition;
    max = max * 1;
    return function maxValue(value) {
      if (isEmpty(value)) {
        return true;
      }
      value = value * 1;
      if (isNaN(value)) {
        return false;
      }
      if (isNaN(max)) {
        console.error(
          `Invalid rule configuration. "max": [${max}] is not a number`
        );
        return false;
      }
      return (
        value <= max ||
        message ||
        `Please enter a number lesser than or equal to ${max}`
      );
    };
  },
  has_special_characters: (ruleDefinition = {}) => {
    const { message } = ruleDefinition || {};

    return function hasSpecialCharacters(value) {
      if (isEmpty(value)) {
        return true;
      }
      return (
        /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(value) ||
        message ||
        "Missing special characters"
      );
    };
  },
  past_date: (ruleDefinition = {}) => {
    let { min, message } = ruleDefinition;
    return function isPastDate(value) {
      if (isEmpty(value)) {
        return true;
      }
      let result = true;
      result = isBefore(new Date(value), new Date());
      if (result && min) {
        if (Number.isInteger(min * 1)) {
          min = format(
            parseISO(subDays(new Date(), min).toISOString()),
            "yyyy-MM-dd"
          );
        }
        result = isAfter(new Date(value), new Date(min));
      }
      return result || message || "Enter a Valid Past Date";
    };
  },
  future_date: (ruleDefinition = {}) => {
    let { max, message } = ruleDefinition;
    return function isFutureDate(value) {
      if (isEmpty(value)) {
        return true;
      }
      let result = true;
      result = isAfter(new Date(value), new Date());
      if (result && max) {
        if (Number.isInteger(max * 1)) {
          max = format(
            parseISO(addDays(new Date(), max).toISOString()),
            "yyyy-MM-dd"
          );
        }
        result = isBefore(new Date(value), new Date(max));
      }
      return result || message || "Enter a Valid Future Date";
    };
  },
  not_past_date: (ruleDefinition = {}) => {
    let { max, message } = ruleDefinition;
    return function isCurrentOrFutureDate(value) {
      if (isEmpty(value)) {
        return true;
      }
      let result = true;
      let dateValue = new Date(value);
      result = isToday(dateValue) || isFuture(dateValue);
      if (result && max) {
        if (Number.isInteger(max * 1)) {
          max = format(
            parseISO(addDays(new Date(), max).toISOString()),
            "yyyy-MM-dd"
          );
        }
        result = isBefore(new Date(value), new Date(max));
      }
      return result || message || "Enter a Valid Future Date";
    };
  },
};

const getRuleInputs = (rule) => {
  const methodDebug = debug.extend("getRuleInputs"); // eslint-disable-line
  const name = Object.keys(rule)[0];
  if (typeof rule[name] == "object") {
    return rule[name];
  }
  return { [name]: rule[name] };
};

const getRuntimeRules = (rulesInConfig = [], options = {}) => {
  const { debugKey } = options || {};
  let methodDebug = debug.extend(`getRuntimeRules${debugKey ? '_' + debugKey : ''}`); // eslint-disable-line
  methodDebug(`rule definitions for [${debugKey}]`, rulesInConfig);
  let applicableRules = [];
  if (!rulesInConfig.length) {
    return applicableRules;
  }
  applicableRules = rulesInConfig.map((configRule) => {
    if (typeof configRule == "function") {
      return configRule;
    }
    const id = Object.keys(configRule)[0];
    if (typeof rules[id] != "function") {
      return;
    }
    const ruleValue = getRuleInputs(configRule);
    return rules[id](ruleValue);
  });
  methodDebug(`Returning runtime rules for [${debugKey}]`, applicableRules);
  return applicableRules.filter((item) => typeof item == "function");
};
export { MESSAGES, rules, getRuntimeRules };
