export default {
  label: "Array",
  filters: ["form_editor", "list_editor"],
  type: "array",
  palette: false,
  description: "Encapsulates an Array",
  templates: [
    {
      id: "default",
      description: "The default Array field",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        label: "Array Title",
        type: "array",
        mode: "input",
        field: {
          label: "Title",
          type: "text",
          default_value: "",
        },
      },
    },
    {
      id: "display",
      description: "Display Mode",
      label: "Display Mode",
      variables: {
        data: {
          display: ["test1", "test2"],
        },
      },
      value: {
        name: "display",
        label: "Array Title",
        type: "array",
        mode: "display",
        field: {
          label: "Title",
          type: "text",
          default_value: "",
        },
      },
    },
    {
      id: "features",
      description: "The features Array field",
      label: "Features",
      variables: {
        data: {
          features: null,
        },
      },
      value: {
        name: "features",
        label: "Features",
        type: "array",
        mode: "input",
        field: {
          label: "Feature",
          type: "feature",
          default_value: {
            name: "",
            description: "",
            constraint: {
              type: "",
              value: "",
              units: "",
            },
          },
        },
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    {
      key: "field",
      mandatory: true,
      description: "The fields in the array",
      affects_display: "yes",
    },
  ],
};
