import { placeholder } from "@/components/fields/attributes";

export default {
  label: "Image",
  type: "image",
  description: "Displays a image field",
  properties: ["name", "label", "type", "mode", "display", "block", "value"],
  templates: [
    {
      id: "default",
      description:
        "Unlike other fields, image fields will display their value if available even in input mode",
      label: "Default",
      variables: {
        data: {
          default: "/test_images/image_640x480.jpeg",
        },
      },
      value: {
        name: "default",
        label: "Image",
        type: "image",
        display: {
          embed: {
            width: "240",
          },
        },
      },
    },

    {
      id: "display",
      description:
        'Use <code>"mode": "display"</code> to view the image. <br/> In display mode, the "change" button is not displayed',
      label: "Display Mode",
      variables: {
        data: {
          some_name: "/test_images/image_640x480.jpeg",
        },
      },
      value: {
        name: "some_name",
        label: "Some Label",
        mode: "display",
        type: "image",
        display: {
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "avatar",
      description:
        'Use the attribute: mode: "avatar" to display the image as an avatar',
      label: "Avatar",
      variables: {
        data: {
          avatar: "/test_images/image_640x480.jpeg",
        },
      },
      value: {
        name: "avatar",
        label: "Some Label",
        type: "image",
        display: {
          mode: "avatar",
          attributes: {
            avatar: {
              width: "240px",
            },
          },
        },
      },
    },
    {
      id: "rounded_avatar",
      description:
        "Use <code>rounded</code> in <code>classes</code> node to <code>avatar</code> to display square avatar.",
      label: "Rounded Avatar",
      variables: {
        data: {
          rounded_avatar: "/test_images/image_640x480.jpeg",
        },
      },
      value: {
        name: "rounded_avatar",
        label: "Avatar",
        type: "image",
        display: {
          mode: "avatar",
          attributes: {
            avatar: {
              classes: ["rounded", "elevation-24"],
              width: "240px",
            },
          },
        },
      },
    },
    {
      id: "input",
      keyword: ["tutorial"],
      label: "Image Input",
      variables: {
        data: {
          input: null,
        },
      },
      value: {
        name: "input",
        label: "Image",
        type: "image",
        display: {
          width: "6",
          attributes: {},
        },
      },
    },
    {
      id: "image_file_upload",
      description: "Image field with source configured as a file.",
      label: "Image File",
      variables: {
        data: {
          image_file: {
            _file: "/test_images/image_640x480.jpeg",
            id: "dynamic_link.gif",
            type: ".gif",
          },
        },
      },
      value: {
        name: "image_file",
        source: "file",
        label: "Image",
        type: "image",
        display: {
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "upload_file_immediate",
      keyword: ["tutorial"],
      description:
        "An image field can be configured to be uploaded immediately or when the form is submitted using the attribute: `allow_submit: true/false`",
      label: "Image upload (immediate)",
      variables: {
        data: {
          upload_file_immediate: null,
        },
      },
      value: {
        name: "upload_file_immediate",
        source: "file",
        label: "Image",
        type: "image",
        display: {
          width: "6",
          attributes: {
            allow_submit: true,
          },
        },
      },
    },
    {
      id: "upload_file_2",
      keyword: ["tutorial"],
      description:
        "An image field can be configured to be uploaded immediately or when the form is submitted using the attribute: `allow_submit: true/false`",
      label: "Image upload (custom icon)",
      variables: {
        data: {
          upload_file_2: null,
        },
      },
      value: {
        name: "upload_file_2",
        source: "file",
        label: "Image",
        icon: "mdi-video-vintage",
        type: "image",
        display: {
          width: "6",
          attributes: {
            allow_submit: true,
            icon: {
              classes: ["pink--text", "pr-2"],
            },
          },
        },
      },
    },
    {
      id: "upload_with_placeholder",
      keyword: ["tutorial"],
      description: "Image upload with placeholder",
      label: "Image upload (placeholder)",
      variables: {
        data: {
          upload_with_placeholder: null,
        },
      },
      value: {
        name: "upload_with_placeholder",
        label: "Some Label",
        mode: "input",
        type: "image",
        display: {
          attributes: {
            if_missing: "placeholder",
            placeholder: "/no_image.jpg",
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "upload_on_image",
      keyword: ["tutorial"],
      description: "Image upload without upload button",
      label: "Image upload (on Image)",
      variables: {
        data: {
          upload_on_image: null,
        },
      },
      value: {
        name: "upload_on_image",
        label: "Some Label",
        mode: "input",
        type: "image",
        display: {
          attributes: {
            if_missing: "placeholder",
            placeholder: "/no_image.jpg",
            upload_on_image: true,
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "display_hide_image",
      keyword: ["tutorial"],
      description:
        'The field can be configured to be hidden completely when no source is available<br/> if the attribute <code>if_missing: "hidden"</code> is specified. This is its default behavior',
      label: "Missing src Display (hidden)",
      value: {
        name: "missing_image_2",
        label: "Some Label",
        mode: "display",
        type: "image",
        display: {
          attributes: {
            if_missing: "hidden",
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "display_no_source",
      keyword: ["tutorial"],
      description:
        'The field can be configured to display a placeholder when no source is available<br/> if the attribute <code>if_missing: "placeholder"</code> is specified',
      label: "Missing src Display (show placeholder)",
      value: {
        name: "missing_image",
        label: "Some Label",
        mode: "display",
        type: "image",
        display: {
          attributes: {
            if_missing: "placeholder",
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "display_character",
      keyword: ["tutorial"],
      description:
        'The field can be configured to display a placeholder when no source is available<br/> if the attribute <code>if_missing: "character"</code> is specified',
      label: "Missing src Display (show character)",
      variables: {
        data: {
          my_char: "Adam",
          new_char: "Weston",
        },
      },
      value: {
        name: "character_image",
        label: "Some Label",
        mode: "display",
        type: "image",
        display: {
          attributes: {
            avatar: {
              width: "90",
            },
            if_missing: "character",
            character: "{_data->my_char}",
          },
          mode: "avatar",
          embed: {
            width: "240",
          },
        },
      },
    },

    {
      id: "theme_based_default_image",
      description:
        "Use <code>conditions</code> to selectively display an image based on the theme mode",
      label: "Default Image Based On Theme",
      value: {
        conditions: [
          {
            rule: "{_display->isDark} == true",
            value: "/schoolbus/welcome.gif",
          },
          {
            rule: "{_display->isDark} == false",
            value: "/schoolbus/schoolbus.png",
          },
        ],
        name: "theme_based",
        label: "Image",
        type: "image",
        display: {
          embed: {
            width: "240",
          },
        },
      },
    },

    {
      id: "image_contain",
      description: `Add <code>"contain":true</code> to contain an image.`,
      label: "Contain Image",
      variables: {
        data: {
          image_contain: "/test_images/image_640x1080.jpeg",
        },
      },
      value: {
        name: "image_contain",
        label: "Image",
        type: "image",
        mode: "display",
        display: {
          attributes: {
            "max-height": 150,
            contain: true,
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "image_clip",
      description: `Add <code>"contain":false</code> to clip an image.`,
      label: "Clip Image",
      variables: {
        data: {
          image_clip: "/test_images/image_640x1080.jpeg",
        },
      },
      value: {
        name: "image_clip",
        label: "Image",
        type: "image",
        mode: "display",
        display: {
          attributes: {
            "max-height": 150,
            contain: false,
          },
          embed: {
            width: "240",
          },
        },
      },
    },
    {
      id: "multiple_input",
      keyword: ["tutorial"],
      label: "Multiple Input",
      variables: {
        data: {
          multiple_input: null,
        },
      },
      value: {
        name: "multiple_input",
        label: "Images",
        type: "image",
        display: {
          width: "6",
          attributes: {
            multiple: true,
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "contain",
      type: "switch",
      value: true,
    },
    placeholder,
    {
      name: "if_missing",
      type: "select",
      value: "hidden",
      options: [
        {
          id: "placeholder",
          label: "placeholder",
        },
        {
          id: "character",
          label: "character",
        },
        {
          id: "hidden",
          label: "hidden",
        },
      ],
    },
  ],
};
