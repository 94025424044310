<template>
  <div aut-nav-bar class="px-1 mb-md-n3 mb-2">
    <v-app-bar-nav-icon
      aut-menu
      v-if="displayMenuButton"
      @click.stop="toggleSidebar"
      class="mr-n2"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="menuButtonColor"
            v-bind="attrs"
            v-on="on"
            class="behavior_action_icon"
            >mdi-menu</v-icon
          >
        </template>
        Toggle Sidebar
      </v-tooltip>
    </v-app-bar-nav-icon>

    <v-app-bar-nav-icon
      v-if="displayApplicationHome"
      @click.stop="goToApplicationHome"
      class="mr-n2"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="applicationHomeColor"
            class="behavior_action_icon"
            v-bind="attrs"
            v-on="on"
            >mdi-home</v-icon
          >
        </template>
        Application Home
      </v-tooltip>
    </v-app-bar-nav-icon>

    <v-app-bar-nav-icon v-if="displayBackButton" @click.stop="goBack">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="backButtonColor"
            class="behavior_action_icon"
            v-bind="attrs"
            v-on="on"
            >mdi-backburger</v-icon
          >
        </template>
        Back
      </v-tooltip>
    </v-app-bar-nav-icon>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { createContext, goToApplicationHome } from "@/util";

const debug = require("debug")("atman.app_bar"); // eslint-disable-line
export default {
  name: "Appbar",
  data() {
    return {
      context: null,
    };
  },
  watch: {
    $route() {
      this.createContext();
    },
  },
  computed: {
    ...mapState(["skin", "preferences"]),
    ...mapGetters(["isClassicLayout"]),
    displayAppBar() {
      return this.isFeatureEnabled("appbar.display");
    },
    displayBackButton() {
      return (
        this.displayAppBar &&
        this.isFeatureEnabled("appbar.back_button.display")
      );
    },
    displayApplicationHome() {
      const display = this.isFeatureEnabled("appbar.application_home.display");
      if (!display) {
        return false;
      }
      const target = this.getFeatureValue(
        "appbar.application_home.target",
        "home"
      );
      if (target == "home") {
        return true;
      }
      return !!this.context?.application;
    },
    backButtonColor() {
      return (
        this.displayAppBar && this.getFeatureValue("appbar.back_button.color")
      );
    },
    applicationHomeColor() {
      return this.getFeatureValue("appbar.application_home.color");
    },
    containerClasses() {
      return "ma-0 pa-0 ";
    },
    displayMenuButton() {
      return this.isFeatureEnabled("appbar.menu_button.display");
    },
    menuButtonColor() {
      return this.getFeatureValue("appbar.menu_button.color");
    },
  },
  mounted() {
    this.createContext();
  },
  methods: {
    createContext() {
      const path = this.$route.fullPath;
      this.context = createContext(path);
    },
    ...mapActions(["toggleSidebar"]),
    goToApplicationHome() {
      const target = this.getFeatureValue(
        "appbar.application_home.target",
        "home"
      );
      if (target == "application_home") {
        goToApplicationHome();
      } else {
        this.$router.push({ path: "/" });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
