import { navigateTo } from "@/util.js";
import confirmationMixin from "./confirmationMixin.js";
import UnderConstruction from "@/views/UnderConstruction.vue";
import { MESSAGES } from "@/constants";
const debug = require("debug")("atman.component.mixin:page"); // eslint-disable-line

export default {
  mixins: [confirmationMixin],
  components: {
    Page: () => import("@/components/Page/Page"),
    UnderConstruction,
  },
  data() {
    return {
      currentContext: null,
      page: null,
      accessControl: null,
      pageKey: 1,
    };
  },
  methods: {
    createPage() {
      const canUserPerformAction = this.$store.getters[`user/canPerformAction`];
      if (!canUserPerformAction("create_pages")) {
        this.displayErrorMessage(MESSAGES.NO_PAGE_REQUEST_ADMIN);
        return;
      }
      this.showConfirmation(
        MESSAGES.NO_PAGE,
        () => {
          const path = encodeURIComponent(this.currentContext.full_path);
          navigateTo(
            this.$router,
            `/editor_page?page=${path}&operation=create`
          );
        },
        () => {
          this.$router.go(-1);
        }
      );
    },
    async fetchFlowDetails(route, createIfUnavailable = true) {
      this.page = null;
      this.accessControl = null;
      const response = await this.$store.dispatch("fetchPage", route);
      if (
        typeof response == "undefined" ||
        response?._server_actions?.navigation
      ) {
        return;
      }
      if (response === false && createIfUnavailable) {
        this.createPage();
      }
      this.page = response?.data;
      this.accessControl = response?.access_control;
      this.pageKey++;
      if (!response?.data?.content && createIfUnavailable) {
        this.createPage();
      }
    },
  },
};
