<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        >Events
        {{
          eventsList.length ? "(" + eventsList.length + ")" : ""
        }}</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-tabs
          v-model="panelModel"
          aut-events-panel
          vertical
          :show-arrows="true"
        >
          <v-tab v-for="event in eventsList" :key="event.id + 'tab'">
            {{ event.id }}
          </v-tab>
          <v-tab-item v-for="event in eventsList" :key="event.id + 'tab'">
            <EventDesigner :event="event" :multiple="false" />
          </v-tab-item>
        </v-tabs>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { eventTypes } from "../util";
import EventDesigner from "./EventDesigner.vue";
import { isPlainObject, defaultsDeep, forOwn } from "lodash";
const debug = require('debug')('atman.components.questionnaire.designer.events')  // eslint-disable-line
export default {
  name: "EventsDesigner",
  components: { EventDesigner },
  props: {
    events: {},
  },
  data() {
    return {
      panelModel: 0,
    };
  },
  computed: {
    eventsList: {
      get() {
        let result = [];
        if (isPlainObject(this.events)) {
          forOwn(this.events, (val, key) => {
            result.push(defaultsDeep({ id: key }, val));
          });
        } else if (Array.isArray(this.events)) {
          result = [...this.events];
        }
        return result;
      },
      set(val) {
        if (isPlainObject(this.events)) {
          val.forEach((item) => {
            this.events[item.id] = item;
          });
        } else {
          this.events = [...this.val];
        }
      },
    },
  },
  created() {
    this.eventTypes = eventTypes;
  },
  mounted() {
    debug(`in EventsDesigner`, this.events);
  },
  methods: {},
};
</script>
