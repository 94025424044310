export default {
  start: {
    type: "trigger_event",
    params: {
      id: ["general-1"],
    },
  },
  categories: {
    general: {
      display: {
        classes: [],
        attributes: {
          color: "#C3DFE0",
        },
      },
      label: "General",
      events: {
        "general-1": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from loss of consciousness",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-2"],
              },
            },
          ],
        },
        "general-2": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from sudden episode of loss of contact with surroundings",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-3"],
              },
            },
          ],
        },
        "general-3": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from uncontrollable shaking of head, arms or legs",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: [
                  "general-4",
                  "general-5",
                  "general-6",
                  "general-7",
                  "general-8",
                ],
              },
            },
          ],
        },
        "general-4": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Abnormal speech",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
        "general-5": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Facial paralysis with sideways deviation of the mouth",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
        "general-6": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Weakness or Paralysis of arms or legs ",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
        "general-7": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Difficulty in walking (exclude individuals with pain or swelling of joints of lower limbs)",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-8"],
              },
            },
          ],
        },
        "general-8": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Any loss of sensation or any abnormal sensation in the face, arms and legs",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-9"],
              },
            },
          ],
        },
        "general-9": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Deterioration of memory within the last five years (applicable to subjects above 30 years of age)",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-10"],
              },
            },
          ],
        },
        "general-10": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Drooping of eyelids",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "temporary",
              name: "Temporary (for a few hours)",
            },
            {
              id: "permanent",
              name: "Permanent",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-11"],
              },
            },
          ],
        },
        "general-11": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Episodes of severe facial pain",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-12"],
              },
            },
          ],
        },
        "general-12": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Headache?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "unilateral",
              name: "Unilateral",
            },
            {
              id: "flashing_lights",
              name: "Associated with flashes of light or circles",
            },
            {
              id: "vomiting",
              name: "Associated with vomiting",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-13"],
              },
            },
          ],
        },
        "general-13": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Any recent change in behavior or personality",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-14"],
              },
            },
          ],
        },
        "general-14": {
          type: "display_question",
          question:
            "Have you or any other member of your family experienced Slowness of movement",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-15"],
              },
            },
          ],
        },
        "general-15": {
          type: "display_question",
          question:
            "Have you or any other member of your family experienced Tremors",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-16"],
              },
            },
          ],
        },
        "general-16": {
          type: "display_question",
          question:
            "Weakness or loss of sensation on one side of body with deviation of face to one side",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-17"],
              },
            },
          ],
        },
        "general-17": {
          type: "display_question",
          question: "Impaired consciouness",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-18"],
              },
            },
          ],
        },
        "general-18": {
          type: "display_question",
          question: "Speech difficulty",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-19"],
              },
            },
          ],
        },
        "general-19": {
          type: "display_question",
          question: "Slurred Speech",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-20"],
              },
            },
          ],
        },
        "general-20": {
          type: "display_question",
          question: "Difficulty in speaking or understanding",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-21"],
              },
            },
          ],
        },
        "general-21": {
          type: "display_question",
          question: "Loss of balance or incoordination while walking",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-22"],
              },
            },
          ],
        },
        "general-22": {
          type: "display_question",
          question: "Dizziness",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-23"],
              },
            },
          ],
        },
        "general-23": {
          type: "display_question",
          question: "Swallowing problems",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-24"],
              },
            },
          ],
        },
        "general-24": {
          type: "display_question",
          question: "Double vision",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-25"],
              },
            },
          ],
        },
        "general-25": {
          type: "display_question",
          question: "Vision impairment",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-1",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-2",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-3",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  _is_exclusive: true,
                  id: ["epilepsy-1"],
                },
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-11",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "unilateral",
                    path: "$.general-12",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-9",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-13",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  _is_exclusive: true,
                  id: ["dementia-1"],
                },
              },
            },
          ],
        },
      },
    },
    epilepsy: {
      display: {
        classes: [],
        attributes: {
          color: "#FBD1A2",
        },
      },
      label: "Epilepsy",
      events: {
        "epilepsy-1": {
          type: "display_question",
          question:
            "Did they pass urine or stool in his/her clothes during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-2"],
              },
            },
          ],
        },
        "epilepsy-2": {
          type: "display_question",
          question:
            "Did they ever injure himself/herself or have tongue bite during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-3"],
              },
            },
          ],
        },
        "epilepsy-3": {
          type: "display_question",
          question: "Was there frothing from mouth during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-4"],
              },
            },
          ],
        },
        "epilepsy-4": {
          type: "display_question",
          question: "Did they have such an episode while asleep?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-5"],
              },
            },
          ],
        },
        "epilepsy-5": {
          type: "display_question",
          question:
            "Was the patient completely unconscious during the episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-6"],
              },
            },
          ],
        },
        "epilepsy-6": {
          type: "display_question",
          question:
            "Has an episode ever occurred WITHOUT preceding emotional or mental stress",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    value: 4,
                    path: "$.epilepsy.yes",
                  },
                ],
              },
              event: {
                type: "display_message",
                params: {
                  message: "Probably Generalised Seizure",
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    value: 4,
                    path: "$.epilepsy.yes",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: "epilepsy-age",
                },
              },
            },
          ],
        },
        "epilepsy-age": {
          type: "display_question",
          question: "Is the patient below 10 years old?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-age",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: "epilepsy-fever",
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-age",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: "epilepsy-duration",
                },
              },
            },
          ],
        },
        "epilepsy-duration": {
          type: "display_question",
          question:
            "Does the patient have more than 1 unprovoked seizure 24 hours apart?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
        },
        "epilepsy-fever": {
          type: "display_question",
          question: "Did the patient have fever?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-fever",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: "epilepsy-no-fever-1",
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-fever",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: "epilepsy-yes-fever-1",
                },
              },
            },
          ],
        },
        "epilepsy-yes-fever-1": {
          type: "display_question",
          question: "Generalised Convulsive?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-yes-fever-2"],
              },
            },
          ],
        },
        "epilepsy-yes-fever-2": {
          type: "display_question",
          question: "Single Episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-yes-fever-3"],
              },
            },
          ],
        },
        "epilepsy-yes-fever-3": {
          type: "display_question",
          question: "Lasts <5 minutes?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-yes-fever-4"],
              },
            },
          ],
        },
        "epilepsy-yes-fever-4": {
          type: "display_question",
          question: "No post ictal weakness?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-yes-fever-5"],
              },
            },
          ],
        },
        "epilepsy-yes-fever-5": {
          type: "display_question",
          question: "No developmental delay/regression?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-yes-fever-6"],
              },
            },
          ],
        },
        "epilepsy-yes-fever-6": {
          type: "display_question",
          question: "Regains consciouness completely within 5 min?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-fever-age"],
              },
            },
          ],
        },
        "epilepsy-fever-age": {
          type: "display_question",
          question: "Is the patient below 5 years old?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
        },
        "epilepsy-no-fever-1": {
          type: "display_question",
          question: "Were the events brief (less than a few seconds)",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-no-fever-2"],
              },
            },
          ],
        },
        "epilepsy-no-fever-2": {
          type: "display_question",
          question: "Symptoms: Blinking/Staring",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-no-fever-3"],
              },
            },
          ],
        },
        "epilepsy-no-fever-3": {
          type: "display_question",
          question: "Symptoms: Multiple per day",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-no-fever-4"],
              },
            },
          ],
        },
        "epilepsy-no-fever-4": {
          type: "display_question",
          question: "Precipitated at bedside by hyperventilation",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-no-fever-5"],
              },
            },
          ],
        },
        "epilepsy-no-fever-5": {
          type: "display_question",
          question: "No developmental delay/regression",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["epilepsy-no-fever-6"],
              },
            },
          ],
        },
        "epilepsy-no-fever-6": {
          type: "display_question",
          question: "Onset after 3 years",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
        },
      },
    },
    dementia: {
      display: {
        classes: [],
        attributes: {
          color: "#E0E2DB",
        },
      },
      label: "Dementia",
      events: {
        "dementia-1": {
          type: "display_question",
          question: "Dementia (TBD)",
          answers: [],
        },
      },
    },
    headache: {
      display: {
        classes: [],
        attributes: {
          color: "#BCD979",
        },
      },
      label: "Headache",
      events: {
        "headache-1": {
          type: "display_question",
          question: "Headache (TBD)",
          answers: [],
        },
      },
    },
  },
  events: {
    display_summary: {
      type: "display_summary",
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 25,
                path: "$.general.no",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "no_issues",
                label: "Inconclusive",
              },
            },
          },
        },
        {
          conditions: {
            any: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-4",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-5",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-6",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-7",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-8",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-16",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-17",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-18",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-19",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-20",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-21",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-22",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-23",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-24",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-25",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "stroke",
                label: "Stroke",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "lessThan",
                value: 3,
                path: "$.epilepsy.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "Uncertain for seizure",
                label: "Uncertain for seizure",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 3,
                path: "$.epilepsy.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              _is_exclusive: true,
              value: {
                id: "Probably Focal Seizure",
                label: "Probably Focal Seizure",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-age",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-duration",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              _is_exclusive: true,
              value: {
                id: "uncertain_for_epilepsy",
                label: "Uncertain for Epilepsy",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-age",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-duration",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              _is_exclusive: true,
              value: {
                id: "epilepsy_diagonosis",
                label: "Epilepsy Diagonosis",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-1",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-2",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-3",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-4",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-5",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-yes-fever-6",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-fever-age",
                  },
                ],
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "typical_febrile_seizure",
                label: "Typical Febrile Seizure",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-1",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-2",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-3",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-4",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-5",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "no",
                path: "$.epilepsy-yes-fever-6",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-fever-age",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "atypical_febrile_seizure",
                label: "Atypical Febrile Seizure",
              },
            },
          },
        },
        {
          conditions: {
            any: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-no-fever-1",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-no-fever-2",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-no-fever-5",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.epilepsy-no-fever-6",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "typical_absence_seizures",
                label: "Typical Absence Seizures",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-1",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-2",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-5",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-6",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-no-fever-3",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-no-fever-4",
                  },
                ],
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "probably_absence_seizures",
                label: "Probably Absence Seizures",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-1",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-2",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-5",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-6",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-3",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-no-fever-4",
                  },
                ],
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "general_seizure",
                label: "Generalised Seizure",
              },
            },
          },
        },
      ],
    },
  },

  summary: {
    title: "Diagnosis",
  },
};
