var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":12}},[_c('div',[_c('v-autocomplete',_vm._b({class:_vm.classes,attrs:{"aut-select-field":_vm.definition.name,"items":_vm.items,"item-text":"name","item-value":"id","label":_vm.label,"deletable-chips":_vm.definition.multiple,"small-chips":_vm.definition.multiple,"rules":_vm.rules,"multiple":_vm.definition.multiple,"chips":_vm.definition.multiple,"hint":_vm.definition.hint},on:{"blur":_vm.emitFieldValue,"change":_vm.emitFieldValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.name || item)+" ")])],1)],1)]}},(_vm.definition.multiple && _vm.attributes.atman_truncate)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[(_vm.fieldValue)?_c('span',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.fieldValue.length > 1 ? " + " + (_vm.fieldValue.length - 1) : "")+" ")]):_vm._e()]):_vm._e()]}}:null,(_vm.appendAttributes.icon)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.appendIconAction.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.appendAttributes.icon)+" ")])]}}],null,false,492014038)},[_vm._v(" "+_vm._s(_vm.appendAttributes.hint)+" ")])]},proxy:true}:(_vm.showDetail)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","aut-show-detail":""},on:{"click":function($event){return _vm.$emit('show_detailed_view')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-table-box-outline ")])]}}])},[_vm._v(" Detailed View ")])]},proxy:true}:null],null,true),model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}},'v-autocomplete',_vm.attributes,false))],1),_c('div',{staticClass:"behavior_menu_container",attrs:{"id":_vm.attachID}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }