const debug = require("debug")("atman.components.time_picker"); //eslint-disable-line

export default {
  label: "Time Picker",
  type: "time_picker",

  description: "Displays a time picker field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  templates: [
    {
      id: "default",
      description: "The default Time Picker field.",
      label: "Default",
      value: {
        name: "some_name",
        label: "Title",
        type: "time_picker",
      },
    },
    {
      id: "default_with_value",
      keyword: ["tutorial"],
      description:
        'Use <code>"type": "time_picker"</code> and <code>value</code> to preconfigure data. In this example, it is set to <b>10:34</b>.',
      label: "Default With Value",
      value: {
        name: "some_name",
        label: "Title",
        type: "time_picker",
        value: "10:34",
      },
    },
  ],
};
