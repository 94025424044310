import {
  color,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";
import { defaultsDeep } from "lodash";
const debug = require("debug")("atman.components.date"); // eslint-disable-line

export default {
  label: "Date",
  type: "date",
  description: "Displays a date field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  searchField: (col) => {
    let result = defaultsDeep(
      {
        mode: "input",
        label: col.label,
        display: {
          classes: ["pt-1"],
          width: "3",
          attributes: {
            store_date_only: true,
            clearable: true,
          },
        },
      },
      col
    );
    debug(`Search Text, search Field`, result);
    return result;
  },
  templates: [
    {
      id: "default",
      description: "The default Date field.",
      label: "Default",
      value: {
        name: "default",
        label: "Date",
        type: "date",
      },
    },
    {
      id: "date_with_format",
      keyword: ["tutorial"],
      description:
        'Use <code>"format": "dd-MM-yyyy"</code> to display date in <b>dd-MM-yyyy</b> format. \
        See <a href="https://date-fns.org/v2.29.2/docs/format" target="_blank">the date-fns library</a>\
        for all the suported formats',
      label: "Date Picker with format",
      variables: {
        data: {
          date_with_format: `${new Date().toISOString()}`,
        },
      },
      value: {
        name: "date_with_format",
        label: "Date",
        type: "date",
        display: {
          attributes: {
            format: "dd-MM-yyyy",
          },
        },
      },
    },
    {
      id: "date_with_format_old",
      keyword: ["tutorial"],
      description:
        'Use <code>"format": "dd-MM-yyyy"</code> to display date in <b>dd-mm-yyyy</b> format.',
      label: "Date Picker with format (Old)",
      variables: {
        data: {
          date_with_format_old: `${new Date().toISOString()}`,
        },
      },
      value: {
        name: "date_with_format_old",
        label: "Date",
        type: "date",
        format: "dd-MM-yyyy",
      },
    },
    {
      id: "date_display",
      keyword: ["tutorial"],
      label: "Display",
      variables: {
        data: {
          date_display: `${new Date().toISOString()}`,
        },
      },
      value: {
        mode: "display",
        name: "date_display",
        label: "Date",
        type: "date",
        display: {
          attributes: {
            format: "dd-MM-yyyy",
          },
        },
      },
    },
    {
      id: "_created_date",
      keyword: ["skip_customisation"],
      label: "Created Date",
      variables: {
        data: {
          _created_date: `${new Date().toISOString()}`,
        },
      },
      value: {
        mode: "display",
        name: "_created_date",
        label: "Created",
        type: "date",
      },
    },
    {
      id: "_updated_date",
      keyword: ["skip_customisation"],
      label: "Updated Date",
      variables: {
        data: {
          _updated_date: `${new Date().toISOString()}`,
        },
      },
      value: {
        mode: "display",
        name: "_updated_date",
        label: "Updated",
        type: "date",
      },
    },
    {
      id: "date_time",
      description:
        'Use <code>"format": "yyyy-MM-dd HH:mm:ss z"</code> to display date along with time.',
      label: "Date & Time",
      variables: {
        data: {
          date_time: `${new Date().toISOString()}`,
        },
      },
      value: {
        label: "Date",
        type: "date",
        display: {
          attributes: {
            format: "yyyy-MM-dd HH:mm:ss z",
          },
        },
        name: "date_time",
      },
    },
    {
      id: "date_with_rule_past_date",
      description:
        'Use the rule <code>"past_date": "true"</code> to display past dates only.',
      label: "Date with rule [past_date]",
      value: {
        label: "Date",
        type: "date",
        name: "date_with_rule_past_date",
        rules: [
          {
            past_date: true,
          },
        ],
      },
    },

    {
      id: "date_with_rule_past_date_with_date_param",
      keyword: ["tutorial"],
      description:
        'Using <code>min</code> in the rule <code>"past_date": "true"</code> you can specify a minimum date. \
In this example, it is set to <b>2021-06-21.</b>',
      label: "Date with rule [past_date] with min date param",
      value: {
        name: "date_with_rule_past_date_with_date_param",

        label: "Date",
        type: "date",
        rules: [
          {
            past_date: {
              min: "2021-06-21",
            },
          },
        ],
      },
    },

    {
      id: "date_with_rule_past_date_with_number_param",
      keyword: ["tutorial"],
      description:
        'The <code>min</code> in the rule <code>"past_date": "true"</code> can also be a number.\
      In this example, it is set to <b>5</b>',
      label: "Date with rule [past_date] with min number param",
      value: {
        name: "date_with_rule_past_date_with_number_param",

        label: "Date",
        type: "date",
        rules: [
          {
            past_date: {
              min: 5,
            },
          },
        ],
      },
    },
    {
      id: "date_with_rule_future_date",
      description:
        'Use the rule <code>"future_date": "true"</code> to display future dates only.',
      label: "Date with rule [future_date]",
      value: {
        name: "date_with_rule_future_date",

        label: "Date",
        type: "date",
        rules: [
          {
            future_date: true,
          },
        ],
      },
    },
    {
      id: "date_with_rule_future_date_with_date_param",
      keyword: ["tutorial"],
      description:
        'Using <code>max</code> in the rule <code>"future_date": "true"</code> you can specify a maximum date.\
      In this example, it is set to <b>2021-11-29.</b>',
      label: "Date with rule [future_date] with max date param",
      value: {
        name: "date_with_rule_future_date_with_date_param",

        label: "Date",
        type: "date",
        rules: [
          {
            future_date: {
              max: "2021-11-29",
            },
          },
        ],
      },
    },
    {
      id: "date_with_rule_future_date_with_number_param",
      keyword: ["tutorial"],
      description:
        'The <code>max</code> in the rule <code>"future_date": "true"</code> can also be a number.\
      In this example, it is set to <b>5</b>',
      label: "Date with rule [future_date] with max number param",
      value: {
        name: "date_with_rule_future_date_with_number_param",

        label: "Date",
        type: "date",
        rules: [
          {
            future_date: {
              max: 5,
            },
          },
        ],
      },
    },
    {
      id: "date_format_elapsed",
      keyword: ["tutorial"],
      description: 'If the format is "elapsed"',
      label: "Elapsed date",
      variables: {
        data: {
          date_format_elapsed: `2021-06-21`,
        },
      },
      value: {
        mode: "display",

        format: "elapsed",
        name: "date_format_elapsed",
        label: "Date",
        type: "date",
      },
    },
  ],
  x_dev_comment:
    "NOTE: Some of these attributes are not working since the component does not delegate to InputText like Date Picker ",
  attributes: [
    color,

    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
    no_value_text,
    no_value_behavior,
    {
      name: "field.color",
      type: "color",
      value: "accent",
    },
    {
      name: "header-color",
      type: "color",
      value: "secondary",
    },
    {
      name: "store_date_only",
      type: "switch",
      hint: "Stores the value as displayed (and not the timestamp)",
      dev_commen: "Used in search",
      value: false,
    },
    {
      name: "format",
      type: "text",
      hint: "Format to display",
      value: "",
      dev_comment:
        "Don't add a value. Will override old configurations where format was specified outside attributes",
    },
  ],
};
