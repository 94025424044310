<template>
  <div>
    <div
      v-if="fieldDefinition && fieldDefinition.description"
      v-html="fieldDefinition.description"
    />
    <v-row dense>
      <list-field
        label="Template"
        :list="templates"
        :value="template"
        @select="selectTemplate"
      />
      <v-col md="9" cols="12" v-if="editorField">
        <div class="text-body-2" v-html="templateDefinition.description"></div>
        <atman-tabs v-model="tabModel" @change="tabModel = $event">
          <v-tab aut-tab-preview>Preview</v-tab>
          <v-tab aut-tab-designer>Designer</v-tab>
          <v-tab aut-tab-json>JSON Editor</v-tab>
          <v-tab aut-tab-variables>Variables</v-tab>
        </atman-tabs>
        <atman-tabs-items v-model="tabModel">
          <v-tab-item aut-tab-item-preview>
            <v-card class="transparent">
              <v-card-text>
                <v-row class="pa-2" aut-field-preview>
                  <v-col
                    :cols="getWidth(editorField)"
                    class="flex-grow-0 flex-shrink-0"
                  >
                    <Field
                      :path="getPath()"
                      :definition="editorField"
                      :context="fieldContextKey"
                      :key="fieldUpdateKey"
                      @update="updateDesigner"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  aut-action-show-template-dialog
                  @click="saveTemplateDialog = true"
                  >Save Template</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item aut-tab-item-designer>
            <v-card class="pa-2" outlined>
              <FieldDesigner
                :definition.sync="editorField"
                @update:definition="handleDesignerUpdate"
                :key="designerUpdateKey"
                :context="fieldContextKey"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item aut-tab-item-json>
            <v-card class="pa-2" outlined>
              <JSONEditor
                aut-editor-json
                v-model="jsonDefinition"
                :context="fieldContextKey"
                @update="handleJSONUpdate"
                :key="jsonUpdateKey"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item aut-tab-item-variables>
            <v-card class="pa-2" outlined>
              <JSONEditor
                aut-editor-json-variables
                v-model="jsonVariablesDefinition"
                :context="variableContextKey"
                :key="jsonUpdateKey"
              />
            </v-card>
          </v-tab-item>
        </atman-tabs-items>
      </v-col>
    </v-row>
    <SaveTemplateDialog
      v-if="saveTemplateDialog"
      :definition="editorField"
      @close="postSaveTemplate"
    />
  </div>
</template>

<script>
import { createContext } from "@/util";
import { store, STORE_CONSTS } from "@/components/fields/store";
import { isEqual } from "lodash";
import { types } from "@/components/fields";
import FieldDesigner from "@/components/fields/Field/FieldDesigner.vue";
import { getWidth } from "@/components/fields/util.js";
import SaveTemplateDialog from "./SaveTemplateDialog";
import { clone, fetchTemplates } from "@/util.js";
import processorsDefinition from "@/components/fields/ProcessorTutorial/definition.js";
import rulesDefinition from "@/components/fields/RuleTutorial/definition.js";
const debug = require("debug")("atman.components.field_definition");
debug("field_definition");
export default {
  name: "FieldDefinition",
  components: {
    FieldDesigner,
    SaveTemplateDialog,
    Field: () => import("@/components/fields/Field"),
    JSONEditor: () => import("@/components/fields/JSONEditor/JSONEditor"),
  },
  data() {
    return {
      saveTemplateDialog: false,
      tabModel: null,
      editorField: null,
      fieldUpdateKey: 1,
      designerUpdateKey: 1,
      jsonUpdateKey: 1,
      template: null,
      templates: [],
      fieldDefinition: null,
    };
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    dynamicVariables() {
      const component = this;
      const state = component.$store.state[`${component.fieldContextKey}`];
      const result = clone({
        _data: state.data,
        _context: state.context,
      });
      return result;
    },
    jsonDefinition() {
      const result = {
        type: "json_editor",
        name: "json_editor_in_tutorial",
        value: clone(this.editorField || {}),
        mode: "explicit",
      };
      debug(`jsonDefinition`, result);
      return result;
    },
    jsonVariablesDefinition() {
      const result = {
        type: "json_editor",
        disabled: true,
        value: clone(this.dynamicVariables),
        mode: "explicit",
      };
      debug(`jsonVariablesDefinition`, result);
      return result;
    },
    templateDefinition() {
      if (!this.template) {
        return;
      }
      return this.templates.find(({ id }) => id == this.template);
    },
  },
  created() {
    this.getWidth = getWidth;
    this.createFieldContext();
    this.createVariableContext();
  },
  watch: {
    template() {
      this.updateField();
    },
  },
  mounted() {
    debug("in mounted of FieldDefinition", this.type);

    this.getTemplates();
  },
  methods: {
    selectTemplate(template) {
      if (this.$route.query.template == template) {
        return;
      }
      const queryParams = Object.assign({}, clone(this.$route.query), {
        template,
      });
      this.$router.replace({ query: queryParams });

      this.template = template;
    },
    createFieldContext() {
      const component = this;
      const currentContext = createContext(`dummyURL`);
      const contextKey = (component.fieldContextKey = `field_tutorial_field`);
      component._createStore(contextKey, store);
      component.$store.commit(
        `${contextKey}/${STORE_CONSTS.CONTEXT}`,
        currentContext
      );
    },
    createVariableContext() {
      const component = this;
      const currentContext = createContext(`dummyURL`);
      const contextKey =
        (component.variableContextKey = `field_tutorial_variable`);
      component._createStore(contextKey, store);
      component.$store.commit(
        `${contextKey}/${STORE_CONSTS.CONTEXT}`,
        currentContext
      );
    },
    handleJSONUpdate(definition) {
      this.editorField = definition;
      this.fieldUpdateKey++;
    },
    postSaveTemplate() {
      this.saveTemplateDialog = false;
      this.getTemplates();
    },
    getPath() {
      if (this.editorField.is_container) {
        return "";
      }
      return this.editorField.name;
    },
    getItemClass(item) {
      const result = item.id == this.template ? "selected" : "";
      return result;
    },
    async getTemplates() {
      let fieldDefinition;
      switch (this.type) {
        case "processor": {
          fieldDefinition = clone(processorsDefinition);
          break;
        }
        case "rule": {
          fieldDefinition = clone(rulesDefinition);
          break;
        }
        default:
          fieldDefinition = this.fieldDefinition = types[this.type];
      }
      debug(`fieldDefinition`, fieldDefinition);
      const dynamicTemplates = await fetchTemplates(this.type);
      this.templates = [
        ...(fieldDefinition.templates || []),
        ...(dynamicTemplates || []),
      ];
      if (!this.templates || !this.templates.length) {
        return;
      }
      const template = this?.$route?.query?.template;
      this.template = template || this.templates[0].id;
    },
    changeTemplate(id) {
      this.template = id || this.template;
      const query = clone(this.$route.query);
      if (this.template == query.template) {
        return;
      }
      query.template = this.template;
      this.$router.push({
        path: this.$route.path,
        query,
      });
    },
    updateDesigner(value) {
      debug(`in updateDesigner`, value);
      if (isEqual(this.editorField.value, value)) {
        return;
      }
      this.editorField.value = clone(value);
      this.designerUpdateKey++;
      this.jsonUpdateKey++;
    },
    updateField() {
      const component = this;
      const field = this.templates.find(({ id }) => id == component.template);
      if (!field) {
        return;
      }
      const data = field?.variables?.data;
      if (data) {
        debug(`updating data`);
        component.$store.commit(
          `${component.fieldContextKey}/${STORE_CONSTS.DATA}`,
          data
        );
      }
      this.editorField = clone(field.value);
      this.fieldUpdateKey++;
      this.designerUpdateKey++;
      this.jsonUpdateKey++;
    },
    handleDesignerUpdate() {
      this.fieldUpdateKey++;
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border: 1px solid black;
}
</style>
