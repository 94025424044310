<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :scrollable="false"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-add-image-dialog :aut-dialog="value.name">
      <DialogTitle @close="$emit('close')" title="Add Image" />
      <v-card-text>
        <div>
          <v-file-input
            v-if="showFileInput"
            aut-field-image-upload
            prepend-inner-icon="mdi-image"
            prepend-icon=""
            ref="fileupload"
            accept="image/*"
            hint="Upload a file from your computer"
            persistent-hint
            label="File"
            name="image_file"
            show-size
            v-bind="attributes"
            v-bind:class="classes"
            truncate-length="15"
            v-model="fileObject"
          >
          </v-file-input>
          <h4 v-if="!source" class="mt-4">Or</h4>
          <v-text-field
            v-if="showTextInput"
            prepend-inner-icon="mdi-web"
            aut-image-link-input
            label="Image Source"
            :name="value.name"
            v-model="linkValue"
            :placeholder="placeholder"
            :rules="rules"
            persistent-hint
            hint="Enter the url to the image"
            :required="true"
          ></v-text-field>
        </div>
        <h4 v-if="!source" class="mt-4">Or</h4>
        <v-text-field
          v-if="showGiphyInput"
          aut-giphy-image-search
          v-model="search"
          hint="Search for an gif to use from Giphy"
          persistent-hint
          label="Search"
          prepend-inner-icon="mdi-magnify"
          clearable
          @keydown.enter.prevent="searchGiphy"
          @blur="searchGiphy"
        ></v-text-field>
        <v-row v-if="results.length">
          <v-col v-for="(item, i) in results" :key="i">
            <v-img
              class="behavior_clickable"
              width="100"
              :src="item.images.downsized.url"
              contain
              :lazy-src="no_image"
              @click.stop="useGiphyImage(item)"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="$emit('close')"
          aut-action-add-image-cancel
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          aut-add-image
          @click="$emit('upload_image')"
          class="ml-2"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { mapState } from "vuex";
import { defaultsDeep } from "lodash";
const axios = require("axios");
const debug = require("debug")("atman.components.field.image.add_dialog"); // eslint-disable-line

export default {
  name: "addImageDialog",
  data() {
    return {
      fileObject: null,
      search: null,
      results: [],
    };
  },
  mixins: [dialogMixin],
  watch: {
    fileObject() {
      if (this.fileObject != null) {
        this.$emit("update", this.fileObject);
      }
    },
  },
  props: {
    dialog: {
      type: Boolean,
    },
    value: {
      type: Object,
    },
    fieldValue: {},
    placeholder: {},
    source: {},
    rules: {},
    classes: {},
  },
  computed: {
    ...mapState(["no_image"]),
    linkValue: {
      get() {
        if (typeof this.fieldValue == "string") {
          return this.fieldValue;
        }
        return "";
      },
      set(value) {
        this.$emit("update", value);
      },
    },
    showTextInput() {
      if (this.source == "link") {
        return true;
      }
      if (typeof this.fieldValue == "string") {
        return true;
      }
      if (!this.source) {
        return true;
      }
      return false;
    },
    showFileInput() {
      if (this.source == "file") {
        return true;
      }
      if (!this.source) {
        return true;
      }
      return false;
    },
    showGiphyInput() {
      if (!this.source) {
        return true;
      }
      return false;
    },
    attributes() {
      const defaultAttributes = {
        multiple: false,
      };

      const attributes = defaultsDeep(
        {},
        this.value?.display?.attributes,
        defaultAttributes
      );

      return attributes;
    },
  },
  methods: {
    useGiphyImage(item) {
      debug(`useGiphyImage`, item);
      this.results = [];
      this.results = [];
      this.$emit("update", item.images.downsized.url);
      this.$emit("close");
    },
    async searchGiphy() {
      if (!this.search) {
        this.$set(this, "results", []);
        return;
      }
      const axiosConfiguration = {
        method: "get",
        url: `https://api.giphy.com/v1/gifs/search?api_key=hevJN72AlHDkIDHjORWQgG4nLx8Ay10c&q=${this.search}&limit=25&offset=0&rating=g&lang=en`,
      };
      const response = await axios(axiosConfiguration).catch((err) => {
        console.error(err);
      });
      debug(`response`, response.data.data);

      this.$set(this, "results", response?.data?.data || []);
    },
  },
};
</script>
