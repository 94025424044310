<template>
  <v-container>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="fieldValue"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <InputText
          v-if="!isDisplayMode && !isReadOnly && propsData"
          v-bind="propsData"
          :key="renderKey"
          :listners="on"
          @typed="fieldValue = $event"
          aut-time-field
        />
      </template>
      <v-time-picker
        v-if="menu"
        v-model="fieldValue"
        v-bind="displayAttributes"
        :ampm-in-title="true"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(fieldValue)">
          OK
        </v-btn>
      </v-time-picker>
    </v-menu>
    <v-text-field
      :label="label"
      v-model="fieldValue"
      readonly
      prepend-icon="mdi-clock-time-four-outline"
      v-if="!isDisplayMode && isReadOnly"
    ></v-text-field>

    <div v-if="isDisplayMode" class="text-left">
      <label v-if="label" class="text-left"> {{ label }}: </label>
      <span class="text-left" aut-field-value>{{ fieldValue }}</span>
    </div>
  </v-container>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import { defaultsDeep } from "lodash";
import { clone } from "@/util";
import InputText from "@/components/fields/InputText/InputText";
const debug = require("debug")("atman.components.time_picker");
debug("time_picker");
export default {
  components: {
    InputText,
  },
  mixins: [fieldMixin],
  name: "TimePicker",
  data() {
    const componentAttributes = {
      "header-color": "secondary",
    };
    return {
      menu: false,
      componentAttributes,
    };
  },
  created() {
    debug(`In created of TimePicker`);
  },
  mounted() {
    debug(`In mounted of TimePicker`);
  },
  methods: {
    _derivePropsData() {
      const propsData = clone(this.$props);

      const attributes = {
        "prepend-inner-icon": "mdi-clock-time-four-outline",
      };

      propsData.value.display.attributes = defaultsDeep(
        {},
        attributes,
        propsData.value.display.attributes
      );

      propsData.value.value = this.fieldValue;
      propsData.value.type = "text";
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
      debug(this.propsData);
    },
  },
};
</script>
