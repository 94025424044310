import {
  validate_on_blur,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  border_color,
  persistent_hint,
  customiseAttribute,
  append_icon,
  prepend_icon,
  autofocus,
  background_color,
  clearable,
  clear_icon,
  flat,
  hint,
  placeholder,
  shaped,
  single_line,
  solo,
  solo_inverted,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

import { defaultsDeep } from "lodash";

import { names as indianStates } from "@/assets/jsons/india_states.js";
import { names as countries } from "@/assets/jsons/countries.js";
const debug = require("debug")("atman.field.select:definition"); // eslint-disable-line
export default {
  label: "Dropdown",
  description: "Displays a dropdown",
  type: "select",
  search: ({ key, searchData, resultObj }) => {
    debug(key, searchData, resultObj);
    let meetsCriteria;
    const searchKeyValues = searchData[key];
    if (searchKeyValues.length == 0) {
      meetsCriteria = true;
    } else {
      const currentValues = resultObj[key];
      debug(`currentValues`, typeof currentValues);
      if (
        typeof currentValues == "string" ||
        typeof currentValues == "number"
      ) {
        meetsCriteria = searchKeyValues.filter((item) => {
          return (
            `${item}`.toLowerCase() == (`${resultObj[key]}` || "").toLowerCase()
          );
        }).length;
      }
      if (Array.isArray(currentValues)) {
        meetsCriteria = searchKeyValues.filter((item) => {
          return currentValues
            .map((item) => item.toLowerCase())
            .includes(item.toLowerCase());
        }).length;
      }
    }
    return meetsCriteria;
  },
  searchField: (col, data = {}) => {
    let result = defaultsDeep(
      {
        mode: "input",
        label: col.label,
        multiple: true,
        display: {
          classes: ["pt-2"],
          width: "3",
          attributes: {
            atman_truncate: true,
          },
        },
      },
      col
    );
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    return result;
  },
  templates: [
    {
      id: "default",
      description: "The default Dropdown field.",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        label: "Dropdown",
        type: "select",
        options: {
          value: [],
        },
      },
    },
    {
      id: "bugf61b7942-282d-54c4-b8a1-7e8114f45cd6",
      description: "",
      keyword: ["bug"],
      label: "Other entry Validation Bug",
      variables: {
        data: {
          "bugf61b7942-282d-54c4-b8a1-7e8114f45cd6": null,
        },
      },
      value: {
        display: {
          attributes: {
            "deletable-chips": true,
            "hide-selected": true,
            other: {
              rules: [
                {
                  regex: {
                    message: "Please enter at least a 4 digit HSN",
                    regex: "^[0-9]{4,}$",
                  },
                },
              ],
            },
            "persistent-hint": true,
            "small-chips": true,
          },
        },
        hint: "Please add your HSN if not in the list",
        label: "HSN Codes",
        mode: "input",
        multiple: true,
        name: "bugf61b7942-282d-54c4-b8a1-7e8114f45cd6",
        options: {
          value: [{ id: "some id", name: "some name" }],
        },
        type: "select",
      },
    },
    {
      id: "bug9f6fb58e-d19f-5646-b664-c0efca9bf294",
      label: "Display mode with other values",
      variables: {
        data: {
          "bug9f6fb58e-d19f-5646-b664-c0efca9bf294": ["1", "2", "other"],
        },
      },
      value: {
        name: "bug9f6fb58e-d19f-5646-b664-c0efca9bf294",
        display: {
          attributes: {
            "deletable-chips": true,
            other: {},
          },
        },
        mode: "display",
        default_value: "",
        label: "Assignee",
        multiple: true,
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "914e93bf-13f2-541e-8ce5-7577ac48c4fa",
      label: "Raw HTML Display mode",
      variables: {
        data: {
          "914e93bf-13f2-541e-8ce5-7577ac48c4fa": [
            "Combs, hair-slides and the like of hard rubber or plastics",
            "Parts of ball-point pens, felt-tipped and other porous-tipped pens and markers, fountain pens and propelling pencils n.e.s, pencil-holders, pen-holders and the like, and duplicating stylos: Other",
            "Refills for ball-point pens, comprising the ball-point and ink-reservoir: Other",
          ],
        },
      },
      value: {
        name: "914e93bf-13f2-541e-8ce5-7577ac48c4fa",
        display: {
          attributes: {
            "deletable-chips": true,
            other: {},
          },
        },
        mode: "display",
        label: "Products",
        multiple: true,
        type: "select",
        options: {
          value: [
            "Combs, hair-slides and the like of hard rubber or plastics",
            "Parts of ball-point pens, felt-tipped and other porous-tipped pens and markers, fountain pens and propelling pencils n.e.s, pencil-holders, pen-holders and the like, and duplicating stylos: Other",
            "Refills for ball-point pens, comprising the ball-point and ink-reservoir: Other",
          ],
        },
      },
    },
    {
      id: "bug539ece3a-6e98-5b7e-84b2-a695f1c0b740",
      label: "Product Selection",
      keyword: ["bug"],
      description: "",
      variables: {
        data: {
          "bug539ece3a-6e98-5b7e-84b2-a695f1c0b740": null,
        },
      },
      value: {
        display: {
          attributes: {
            "deletable-chips": true,
            "hide-selected": true,
            other: {
              rules: null,
            },
            "persistent-hint": true,
            "small-chips": true,
          },
        },
        hint: "Please add your Product if it is not in the list",
        label: "Products",
        mandatory: true,
        mode: "input",
        multiple: true,
        name: "bug539ece3a-6e98-5b7e-84b2-a695f1c0b740",
        options: {
          value: [
            "Combs, hair-slides and the like of hard rubber or plastics",
            "Parts of ball-point pens, felt-tipped and other porous-tipped pens and markers, fountain pens and propelling pencils n.e.s, pencil-holders, pen-holders and the like, and duplicating stylos: Other",
            "Refills for ball-point pens, comprising the ball-point and ink-reservoir: Other",
          ],
        },
        type: "select",
      },
    },
    {
      id: "static_dropdown",
      keyword: ["tutorial"],
      description:
        "Example of a static (self-contained) dropdown containing key-value pairs",
      label: "A Static Dropdown (ID & Name)",
      variables: {
        data: {
          static_dropdown: "middle",
        },
      },
      value: {
        name: "static_dropdown",
        label: "Level",
        type: "select",
        display: {
          attributes: {
            "hide-selected": false,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "2a74a835-13ec-5d20-aead-1a8242a2723e",
      keyword: ["tutorial"],
      label: "Example of a no_value_text",
      variables: {
        data: {
          "2a74a835-13ec-5d20-aead-1a8242a2723e": null,
        },
      },
      value: {
        display: {
          attributes: {
            no_value_text: "Not Available",
            label: {
              classes: ["behavior_block", "font-weight-bold"],
              display: {
                mode: "hidden",
              },
            },
            type: "number",
          },
        },
        name: "2a74a835-13ec-5d20-aead-1a8242a2723e",
        label: "Level",
        mode: "display",
        type: "select",
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "e39668ff-ca07-5e29-8e24-2f075efdf086",
      keyword: ["tutorial"],
      label: "Example of a masked value",
      variables: {
        data: {
          "e39668ff-ca07-5e29-8e24-2f075efdf086": "middle",
        },
      },
      value: {
        masked: true,
        display: {
          attributes: {
            no_value_text: "Not Available",
            label: {
              classes: ["behavior_block", "font-weight-bold"],
              display: {
                mode: "hidden",
              },
            },
            type: "number",
          },
        },
        name: "e39668ff-ca07-5e29-8e24-2f075efdf086",
        label: "Level",
        mode: "display",
        type: "select",
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "template_field_3",
      description:
        "Example of a static (self-contained) dropdown containing simple text values",
      label: "A Static Dropdown (Simple text values)",
      variables: {
        data: {
          template_field_3: null,
        },
      },
      value: {
        name: "template_field_3",
        label: "Level",
        type: "select",
        value: "Middle Readers",
        options: {
          value: [
            "Early Readers",
            "Middle Readers",
            "Advanced Readers",
            "Adults",
          ],
        },
      },
    },
    {
      id: "dynamic_dropdown",
      keyword: ["tutorial"],
      description:
        "Example of a dynamic dropdown i.e values are fetched from the server",
      label: "Display a Dynamic Dropdown",
      variables: {
        data: {
          dynamic_dropdown: "1",
        },
      },
      value: {
        name: "dynamic_dropdown",
        display: {
          width: "6",
        },
        label: "Assignee",
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "multiple_dynamic_dropdown",
      description:
        'Set <code>"multiple": "true"</code> to allow multiple selection',
      label: "Dynamic Dropdown with Multiple",
      variables: {
        data: {
          multiple_dynamic_dropdown: [],
        },
      },
      value: {
        name: "multiple_dynamic_dropdown",
        display: {
          attributes: {
            "deletable-chips": true,
          },
          width: "6",
        },
        default_value: "",
        label: "Assignee",
        multiple: true,
        value: ["1", "2"],
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "dropdown_multiple_other",
      description:
        'Add attribute <code>"other": {}</code> to allow other option',
      label: "Dropdown (Multiple) with Other Option",
      variables: {
        data: {
          dropdown_multiple_other: ["1", "2", "other"],
        },
      },
      value: {
        name: "dropdown_multiple_other",
        display: {
          attributes: {
            "deletable-chips": true,
            other: {},
          },
        },
        default_value: "",
        label: "Assignee",
        multiple: true,
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "dropdown_single_other",
      description: "Example of single select with other enabled",
      label: "Dropdown (Single) with Other Option",
      variables: {
        data: {
          dropdown_single_other: "other",
        },
      },
      value: {
        name: "dropdown_single_other",
        display: {
          attributes: {
            other: {},
          },
        },
        default_value: "",
        label: "Assignee",
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "dropdown_single_other_validation",
      description: "The other field can contain custom validations",
      label: "Dropdown (Single) with Other & Validation",
      variables: {
        data: {
          dropdown_single_other_validation: null,
        },
      },
      value: {
        name: "dropdown_single_other_validation",
        display: {
          attributes: {
            other: {
              rules: [
                {
                  regex: {
                    regex: "^[A-Za-z0-9]{10,10}$",
                    message:
                      "Please enter a valid PAN (Alphanumeric, 10 characters)",
                  },
                },
              ],
            },
          },
        },
        default_value: "",
        label: "Assignee",
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "dropdown_multiple_other_validation",
      description: "The validation will apply to each new field",
      label: "Dropdown (Multiple) with Other & Validation",
      variables: {
        data: {
          dropdown_multiple_other_validation: [],
        },
      },
      value: {
        name: "dropdown_multiple_other_validation",
        display: {
          attributes: {
            other: {
              rules: [
                {
                  regex: {
                    regex: "^[A-Za-z0-9]{10,10}$",
                    message:
                      "Please enter a valid PAN (Alphanumeric, 10 characters)",
                  },
                },
              ],
            },
          },
        },
        default_value: "",
        label: "Assignee",
        multiple: true,
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "detailed_dropdown",
      description: 'Set <code>"detail": { fields: [] }</code> to detailed view',
      label: "Dropdown with Detailed View",
      variables: {
        data: {
          detailed_dropdown: null,
        },
      },
      value: {
        name: "detailed_dropdown",
        display: {
          attributes: {
            detail: {
              fields: [],
            },
            "deletable-chips": true,
          },
          width: "6",
        },
        default_value: "",
        label: "Assignee",
        multiple: true,
        value: ["1", "2"],
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "combo_dynamic_dropdown_multiple",
      description: "Update master list",
      label: "Dropdown (Multiple) allowing update of master list",
      variables: {
        data: {
          combo_dynamic_dropdown_multiple: [],
        },
      },
      value: {
        name: "combo_dynamic_dropdown_multiple",
        display: {
          width: "6",
          attributes: {
            "small-chips": true,
            atman_truncate: true,
            "deletable-chips": true,
            "hide-selected": true,
          },
        },
        default_value: "",
        label: "Assignee",
        multiple: true,
        type: "select",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
            submit: {
              url: "users",
              type: "post",
            },
          },
        },
      },
    },

    {
      id: "static_custom_label",
      keyword: ["tutorial"],
      description: "An example of Static dropdown with preset custom value.",
      label: "Static with custom Label",
      variables: {
        data: {
          static_custom_label: null,
        },
      },
      value: {
        name: "static_custom_label",
        value: "1",
        label: "Level",
        type: "select",
        options: {
          dynamic_label: "{first_name||Unknown} {last_name||Unknown}",
          value: [
            {
              first_name: "Albert",
              name: "ea234d57-4d41-5a19-a4b1-d75d945fd852",
              last_name: "Guzman",
              id: "1",
            },
            {
              first_name: "Maggie",
              name: "eb535969-0733-5a08-a428-9b6ebee65d86",
              last_name: "Marshall",
              id: "2",
            },
            {
              first_name: "Jeff",
              name: "dfd1f9bf-6f62-5008-b6b4-fb829d15ab3a",
              last_name: "Harper",
              id: "2",
            },
            {
              first_name: "Viola",
              name: "046e8be6-5899-5a63-9cdc-7d92ad1a345b",
              last_name: "Sanders",
              id: "3",
            },
            {
              name: "ca2ce893-d276-5dee-a990-dc6c682cf32c",
              id: "4",
            },
          ],
        },
      },
    },
    {
      id: "dynamic_custom_label",
      keyword: ["tutorial"],
      description: "An example of Dynamic dropdown with preset custom label.",
      label: "Dynamic with custom Label",
      variables: {
        data: {
          dynamic_custom_label: null,
        },
      },
      value: {
        name: "dynamic_custom_label",
        label: "User",
        value: "1",
        type: "select",
        options: {
          dynamic_label: "{first_name} {last_name}",
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "slider",
      description:
        'Use <code>"slider": "true"</code> to display a Slider instead of a Dropdown',
      label: "Slider",
      variables: {
        data: {
          slider: null,
        },
      },
      value: {
        name: "slider",
        label: "Reader Level",
        type: "select",
        value: "middle",
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
        display: {
          attributes: {
            slider: true,
          },
        },
      },
    },
    {
      id: "slider_dynamic",
      description: "Example of a slider with dynamic data",
      keyword: ["tutorial"],
      label: "Slider (Dynamic)",
      variables: {
        data: {
          slider_dynamic: null,
        },
      },
      value: {
        name: "slider_dynamic",
        label: "Horizon",
        type: "select",
        value: "Less than 5 years",
        options: {
          apis: {
            data: {
              url: "/applications/finserv_crm?lookup=horizons",
              type: "get",
            },
          },
        },
        display: {
          attributes: {
            slider: true,
          },
        },
      },
    },
    {
      id: "auto_correction",
      description:
        "A select marked as multiple will convert its value to an array automatically when loaded<br/>In this example, if you see the 'variables' before doing any change, you will notice that the value of 'skills' is the text: 'Plumber'. Once a change is made, the value becomes a list of values",
      keyword: ["tutorial"],
      label: "Select with a single value marked as multiple",
      variables: {
        data: {
          auto_correction: "Plumber",
        },
      },
      value: {
        label: "Skills",
        mode: "input",
        multiple: true,
        name: "auto_correction",
        options: {
          value: ["Plumber", "Electrician", "Nurse", "Welder", "Driver"],
        },
        type: "select",
      },
    },
    {
      id: "indian_state",
      description: "States Dropdown",
      keyword: ["address", "tutorial"],
      label: "State",
      variables: {
        data: {
          indian_state: null,
        },
      },
      value: {
        name: "indian_state",
        label: "State",
        type: "select",
        display: {
          width: "6",
        },
        options: {
          value: indianStates,
        },
      },
    },
    {
      id: "country",
      description: "Countries Dropdown",
      keyword: ["address"],
      label: "Country",
      variables: {
        data: {
          country: null,
        },
      },
      value: {
        name: "country",
        label: "Country",
        type: "select",
        display: {
          width: "6",
        },
        options: {
          value: countries,
        },
      },
    },
    {
      id: "expansion_panel",
      label: "Dropdown Display (expansion panel)",
      keyword: ["tutorial"],
      variables: {
        data: {
          expansion_panel: ["Plumber", "Electrician"],
        },
      },
      value: {
        name: "expansion_panel",
        label: "Job",
        multiple: true,
        type: "select",
        mode: "display",
        display: {
          attributes: {
            layout: "open_panel",
          },
          width: "6",
        },
        options: {
          value: ["Plumber", "Electrician", "Nurse", "Welder", "Driver"],
        },
      },
    },
    {
      id: "bug_empty_string_in_array",
      label: "Bug Empty string in array",
      keyword: ["tutorial", "bug"],
      variables: {
        data: {
          bug_empty_string_in_array: [""],
        },
      },
      value: {
        name: "bug_empty_string_in_array",
        label: "Job",
        multiple: true,
        type: "select",
        mode: "display",
        display: {
          attributes: {
            no_value: {
              text: "Not Available",
              behavior: "hide_field",
            },
            layout: "open_panel",
          },
          width: "6",
        },
        options: {
          value: ["Plumber", "Electrician", "Nurse", "Welder", "Driver"],
        },
      },
    },
    {
      id: "bug_id_as_number",
      label: "Bug Typecasting ID",
      keyword: ["tutorial", "bug"],
      variables: {
        data: {
          bug_id_as_number: 1,
        },
      },
      value: {
        name: "bug_id_as_number",
        label: "Job",
        type: "select",
        mode: "input",
        display: {
          attributes: {
            no_value: {
              text: "Not Available",
              behavior: "hide_field",
            },
          },
          width: "6",
        },
        options: {
          value: [
            {
              id: "1",
              name: "Plumber",
            },
            {
              id: "2",
              name: "Electrician",
            },
            {
              id: "3",
              name: "Nurse",
            },
            {
              id: "4",
              name: "Welder",
            },
            {
              id: "5",
              name: "Driver",
            },
          ],
        },
      },
    },
    {
      id: "append_action",
      description: "Append an action",
      label: "Append Action",
      variables: {
        data: {
          append_action: null,
        },
      },
      value: {
        name: "append_action",
        label: "Dropdown",
        type: "select",
        options: {
          value: [],
        },
        display: {
          attributes: {
            append: {
              icon: "mdi-home",
              hint: "Home",
              action: {
                type: "navigation",
                url: "/dashboard",
              },
            },
          },
        },
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
    {
      key: "multiple",
      description: "controls whether the user can select multiple options",
      affects_display: "yes",
      values: [
        {
          value: "true",
          description: "User will be able to select multiple options",
        },
        {
          value: "false",
          description: "User will be able to select a single option",
        },
      ],
    },
  ],
  attributes: [
    validate_on_blur,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    flat,
    dense,
    border_color,
    persistent_hint,
    append_icon,
    prepend_icon,
    autofocus,
    background_color,
    clearable,
    clear_icon,
    hint,
    placeholder,
    shaped,
    single_line,
    solo,
    solo_inverted,
    no_value_text,
    no_value_behavior,
    {
      name: "hide-selected",
      type: "switch",
      hint: "Don't show the selected in the dropdown list",
      value: false,
      dev_comment:
        "NOTE: Toggling this to true can cause several tests in address.spec to fail",
    },
    {
      name: "open-on-clear",
      type: "switch",
      value: false,
    },
    {
      name: "chips",
      type: "switch",
      value: false,
    },
    {
      name: "slider",
      type: "switch",
      value: false,
    },
    {
      name: "deletable-chips",
      type: "switch",
      value: false,
    },
    {
      name: "atman_truncate",
      hint: "show only one item even if more are selected",
      type: "switch",
      value: false,
    },
    {
      name: "disable-lookup",
      type: "switch",
      hint: "Disables keyboard lookup",
      value: false,
    },
    customiseAttribute("clearable", { value: true }),
    customiseAttribute("color", { value: "var(--v-secondary-base)" }),

    {
      name: "item-color",
      type: "color",
      value: "info",
    },

    {
      name: "menu-props",
      type: "select",
      value: "offset-y",
      options: [
        {
          id: "offset-y",
          label: "offset-y",
        },
      ],
    },
    {
      name: "layout",
      type: "select",
      value: "",
      options: [
        {
          id: "open_panel",
          label: "Open Panel",
        },
        {
          id: "closed_panel",
          label: "Closed Panel",
        },
      ],
    },
    {
      name: "other",
    },
    {
      name: "append.icon",
      type: "text",
    },
    { name: "append.hint", type: "text" },
    { name: "append.action.type", type: "text" },
    { name: "append.action.url", type: "text" },
  ],
  classes: [],
};
